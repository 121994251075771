@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.o-inner {
    max-width: calc(1000px + 40px);
    padding: 0 20px;
    margin: 0 auto;
    &--wide {
        max-width: calc(1400px + 40px);
    }
    &--narrow {
        max-width: calc(800px + 40px);
    }
}
