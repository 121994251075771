@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.c-it-talent {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    &__container {
        & + & {
            margin-top: 100px;
            @include s.breakpoint(sp) {
                margin-top: 70px;
            }
        }
    }
    &__block {
        & + & {
            margin-top: 50px;
            @include s.breakpoint(sp) {
                margin-top: 35px;
            }
        }
    }
    &__list {
        padding-left: 10px;
        margin-top: 15px;
        li {
            line-height: calc(24 / 14);
            position: relative;
            padding-left: 20px;
            & + li {
                margin-top: 10px;
            }
            &::before {
                content: "";
                display: inline-block;
                margin-right: 6px;
                width: 7px;
                height: 7px;
                background-color: #dc6190;
                border-radius: 50%;
                position: absolute;
                top: 9px;
                left: 0;
            }
            a {
                color: s.$c-primary;
                text-decoration: underline;
            }
        }
    }
    &__top {
        display: flex;
        justify-content: space-between;
        @include s.breakpoint(sp) {
            flex-direction: column;
        }
        .box {
            width: 30%;
            @include s.breakpoint(sp) {
                width: 100%;
                & + .box {
                    margin-top: 30px;
                }
            }
            img {
                @include s.breakpoint(sp) {
                    width: 100%;
                }
            }
            p {
                margin-top: 20px;
                line-height: calc(24 / 14);
            }
            .c-it-talent__list {
                padding-left: 0;
            }
        }
    }
    &__bottom {
        display: flex;
        justify-content: space-between;
        @include s.breakpoint(sp) {
            flex-direction: column;
            margin-top: 30px;
        }
        .left {
            width: 30.5%;
            @include s.breakpoint(sp) {
                width: 100%;
            }
            img {
                @include s.breakpoint(sp) {
                    width: 100%;
                }
            }
            p {
                margin-top: 10px;
                text-align: center;
                font-size: 1.2rem;
            }
        }
        .right {
            width: 65%;
            @include s.breakpoint(sp) {
                width: 100%;
            }
            p {
                line-height: calc(24 / 14);
                @include s.breakpoint(sp) {
                    margin-top: 20px;
                }
                & + p {
                    margin-top: 25px;
                }
            }
        }
    }
}
