@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.c-news {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    &__list {
    }
    &__item {
        position: relative;
        background-color: rgba($color: s.$c-gray, $alpha: 0.8);
        padding: 60px 35px 10px;
        @include s.breakpoint(sp) {
            padding: 60px 20px 10px;
        }
        & + li {
            margin-top: 20px;
        }
        time {
            position: absolute;
            top: 0;
            right: 0;
            @include m.gradationPink();
            line-height: 100%;
            padding: 13px 20px;
        }
        h3 {
            color: s.$c-primary;
            font-size: 1.7rem;
            line-height: calc(18 / 17);
            @include s.font_bold();
        }
        p {
            margin-top: 25px;
            line-height: calc(22 / 14);
        }
        .labels {
            margin-top: 25px;
            li {
                background-color: s.$c-pink;
                padding: 5px 20px;
                color: s.$c-white;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2rem;
                line-height: 100%;
                border-radius: 10px;
                margin-bottom: 10px;
                margin-right: 10px;
                @include s.font_bold();
            }
        }
    }
    &__btn {
        width: 50px;
        height: 50px;
        @include s.transition-base();
        @include m.gradationOrange();
        @include m.gradationHover();
        @include s.font_bold();
        border-radius: 50%;
        color: s.$c-white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px auto 0;
        &:hover {
            cursor: pointer;
        }
    }
    &__pagination {
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        .prev,
        .next {
            border-radius: 0;
            background: none;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 13px 6px 0;
            border-color: transparent #d3166b transparent transparent;
            margin-bottom: 5px;
            @include s.font_bold();
        }
        .prev {
            margin-right: 15px;
        }
        .next {
            transform: rotate(180deg);
            margin-left: 15px;
        }
        .number {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            li {
                margin: 0 5px 5px;
                a {
                    min-width: 28px;
                    min-height: 28px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: s.$c-primary;
                    background-color: #fce2d4;
                    border-radius: 4px;
                    @include s.font_bold();
                }
                &.current {
                    a {
                        color: s.$c-white;
                        @include m.gradationOrange();
                        @include m.gradationHover();
                    }
                }
            }
        }
    }
}
