@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.o-table {
    width: 100%;
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    th,
    td {
        padding: 10px 15px;
        vertical-align: top;
        @include s.breakpoint(sp) {
            padding: 5px 10px;
        }
    }
    th {
        background-color: #ffeded;
        border: 1px solid #e8a0ba;
        text-align: center;
        @include s.font_regular();
        @include s.breakpoint(sp) {
        }
    }
    td {
        background-color: #ffffff;
        border: 1px solid #e8a0ba;
        @include s.breakpoint(sp) {
        }
        span {
            @include s.font_bold();
        }
    }
}
