@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.l-contents {
    background-color: rgba($color: s.$c-gray, $alpha: 0.8);
    padding: 60px 40px;
    @include s.breakpoint(sp) {
        padding: 20px;
    }
    & + & {
        margin-top: 85px;
        @include s.breakpoint(sp) {
            margin-top: 40px;
        }
    }
}
