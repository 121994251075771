@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.o-navSp {
    display: none;
    @include s.breakpoint(tablet) {
        display: block;
        visibility: hidden;
        opacity: 0;
        padding: 10% 5% 20%;
        position: fixed;
        top: 0;
        left: 0;
        overflow-y: scroll;
        opacity: 0;
        z-index: 99;
        height: 100%;
        width: 0;
        @include m.gradationPink();
        @include s.transition-base();
        &.is_active {
            visibility: visible;
            opacity: 1;
            width: 100%;
        }
    }
    &__list {
    }
    &__item {
        @include s.font_bold();
        & + & {
            margin-top: 25px;
        }
        .block {
            margin-top: 25px;
            .label {
                margin-right: 35px;
                @include s.font_bold();
                line-height: 100%;
                font-size: 1.2rem;
                letter-spacing: 0.01em;
                width: 150px;
                min-width: 150px;
                height: 30px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: s.$c-white;
                @include m.gradationRed();
            }
            ul {
                margin-top: 20px;
                padding-left: 20px;
                li {
                    display: flex;
                    align-items: center;
                    line-height: 100%;
                    font-size: 1.2rem;
                    @include s.font_bold();
                    & + li {
                        margin-top: 15px;
                    }
                    &::before {
                        content: "";
                        display: inline-block;
                        margin-right: 10px;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: s.$c-primary;
                    }
                    &.displayNone {
                        display: block;
                    }
                }
            }
            &--green {
                .label {
                    @include m.gradationGreen();
                }
                ul {
                    li {
                        &::before {
                            background-color: s.$c-green;
                        }
                    }
                }
            }
            &--blue {
                .label {
                    @include m.gradationBlue();
                }
                ul {
                    li {
                        &::before {
                            background-color: s.$c-blue;
                        }
                    }
                }
            }
            &--orange {
                .label {
                    @include m.gradationOrange2();
                }
                ul {
                    li {
                        &::before {
                            background-color: s.$c-orange;
                        }
                    }
                }
            }
        }
        .flex {
            margin-top: 25px;
        }
    }
}
