@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.c-products {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    &--counter {
    }
    &--homori {
    }

    &__head {
        display: flex;
        justify-content: space-between;
        @include s.breakpoint(sp) {
            flex-direction: column;
        }
        .logo {
            width: 30%;
            @include s.breakpoint(sp) {
                width: 100%;
            }
            .circle {
                background-color: #ffdfdf;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 240px;
                height: 240px;
                min-width: 240px;
                min-height: 240px;
                margin: 0 auto;
                @include s.breakpoint(tablet) {
                    width: 180px;
                    height: 180px;
                    min-width: 180px;
                    min-height: 180px;
                    img {
                        max-width: 150px;
                    }
                }
                @include s.breakpoint(sp) {
                    width: 240px;
                    height: 240px;
                    min-width: 240px;
                    min-height: 240px;
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
        .contents {
            width: 65%;
            @include s.breakpoint(sp) {
                width: 100%;
                margin-top: 30px;
            }
            h3 {
                color: s.$c-primary;
                font-size: 2rem;
                line-height: 100%;
                @include s.font_bold();
            }
            p {
                margin-top: 10px;
                font-size: 1.5rem;
                line-height: calc(24 / 15);
                span {
                    @include s.font_bold();
                }
            }
            .block {
                margin-top: 35px;
                h4 {
                    font-size: 1.7rem;
                    line-height: calc(18 / 17);
                    @include s.font_bold();
                }
                p {
                    margin-top: 15px;
                }
            }
        }
    }
    &__body {
        margin-top: 60px;
        background-color: rgba($color: s.$c-gray, $alpha: 0.8);
        padding: 60px;
        @include s.breakpoint(tablet) {
            padding: 40px;
        }
        @include s.breakpoint(sp) {
            padding: 20px;
        }
        h3 {
            text-align: center;
            color: s.$c-primary;
            font-size: 2rem;
            line-height: 100%;
            @include s.font_bold();
        }
        .stepList {
            position: relative;
            margin-top: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include s.breakpoint(sp) {
                flex-direction: column;
            }
            li {
                position: relative;
                background-color: #dc6190;
                border-radius: 50%;
                width: 120px;
                height: 120px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0 27px;
                @include s.breakpoint(tablet) {
                    width: 100px;
                    height: 100px;
                    min-width: 100px;
                    min-height: 100px;
                    margin: 0 15px;
                }
                @include s.breakpoint(sp) {
                    width: 150px;
                    height: 150px;
                    margin: 20px 0;
                }
                .circle {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 5px;
                    color: #f44387;
                    @include s.font_bold();
                    width: 40px;
                    height: 40px;
                    background-color: s.$c-white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 100%;
                    letter-spacing: 0;
                    @include s.breakpoint(tablet) {
                        width: 30px;
                        height: 30px;
                    }
                    @include s.breakpoint(sp) {
                        width: 45px;
                        height: 45px;
                    }
                }
                p {
                    text-align: center;
                    @include s.font_bold();
                    line-height: calc(18 / 14);
                    color: s.$c-white;
                    padding-top: 10px;
                    @include s.breakpoint(tablet) {
                        font-size: 1.2rem;
                    }
                    @include s.breakpoint(sp) {
                        font-size: 1.4rem;
                    }
                    span {
                        font-size: 1.2rem;
                    }
                    &.lines {
                        padding-top: 25px;
                    }
                }
                &.dot {
                    background-color: #faa5c5;
                    width: 7px;
                    height: 7px;
                    margin: 0;
                    min-width: auto;
                    min-height: auto;
                    &::before,
                    &::after {
                        position: absolute;
                        content: "";
                        display: inline-block;
                        background-color: #faa5c5;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                    }
                    &::before {
                        left: -18px;
                        @include s.breakpoint(tablet) {
                            left: -9px;
                        }
                        @include s.breakpoint(sp) {
                            left: auto;
                            top: -12px;
                        }
                    }
                    &::after {
                        right: -18px;
                        @include s.breakpoint(tablet) {
                            right: -9px;
                        }
                        @include s.breakpoint(sp) {
                            right: auto;
                            bottom: -12px;
                        }
                    }
                }
            }
        }
        .stepBlock {
            margin-top: 65px;
            display: flex;
            justify-content: space-between;
            @include s.breakpoint(sp) {
                flex-direction: column;
            }
            & + .stepBlock {
                margin-top: 45px;
            }
            .image {
                width: 32%;
                text-align: center;
                @include s.breakpoint(sp) {
                    width: 100%;
                }
                p {
                    margin-top: 15px;
                    font-size: 1.2rem;
                }
            }
            .contents {
                width: 65%;
                @include s.breakpoint(sp) {
                    width: 100%;
                    margin-top: 20px;
                }
                .title {
                    display: flex;
                    align-items: center;
                    span {
                        background-color: #dc6190;
                        color: s.$c-white;
                        @include s.font_bold();
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        line-height: 100%;
                        letter-spacing: 0;
                    }
                    h4 {
                        color: s.$c-primary;
                        font-size: 1.6rem;
                        line-height: 100%;
                        @include s.font_bold();
                        margin-left: 15px;
                    }
                }
                p {
                    margin-top: 25px;
                    line-height: calc(30 / 14);
                    @include s.breakpoint(sp) {
                        margin-top: 10px;
                    }
                }
                .box {
                    & + .box {
                        margin-top: 45px;
                    }
                }
                a {
                    color: #d9387e;
                    text-decoration: underline;
                }
            }
        }
    }
    &__foot {
        margin-top: 50px;
        h3 {
            text-align: center;
            @include s.font_bold();
            font-size: 1.6rem;
            line-height: 100%;
            margin-bottom: 15px;
        }
        p {
            text-align: center;
            @include s.font_bold();
            line-height: calc(30 / 14);
            &.nomal {
                @include s.font_regular();
            }
        }
        .o-button {
            margin: 40px auto;
        }
    }

    &__linkText {
        margin-top: 10px;
        a {
            color: s.$c-primary;
        }
    }
}
