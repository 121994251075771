@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.c-sabaepc {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    .pseudo {
        @include s.breakpoint(sp) {
            display: block;
            content: "";
            height: 5px;
        }
    }
    .pc-br {
        display: inline-block;
        @include s.breakpoint(sp) {
            display: none;
        }
    }

    .top {
        display: flex;
        flex-direction: column;
        width: 100%;

        a {
            position: relative;
            margin-top: 40px;

            @include s.breakpoint(sp) {
                margin-top: 20px;
            }
        }
        img {
            height: auto;
        }
    }
    .btn {
        @include s.font_bold();
        background-color: #e31851;
        color: #fff;
        font-size: 1.8rem;
        border-radius: 40px;
        margin: 0 auto;
        padding: 20px 80px 20px 80px;

        @include s.breakpoint(tablet) {
            padding: 15px 75px 15px 75px;
        }
        @include s.breakpoint(sp) {
            font-size: 1rem;
            border-radius: 30px;
            padding: 10px 30px 10px 30px;
        }
    }
    .btnsub {
        @include s.font_bold();
        display: inline-block;
        font-size: 1.8rem;
        background-color: #ffe063;
        border-radius: 40px;
        margin: 20px auto;
        padding: 20px 80px 20px 80px;
        @include s.breakpoint(sp) {
            font-size: 1.4rem;
            margin: 10px auto;
            padding: 10px 30px 10px 30px;
        }
    }
    .home {
        font-size: 1.8rem;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        padding-top: 50px;
        padding-bottom: 50px;
        line-height: 30px;
        @include s.breakpoint(sp) {
            font-size: 1.4rem;
            text-align: initial;
            padding-top: 20px;
            padding-bottom: 40px;
            line-height: 23px;
        }

        .intro {
            span {
                @include s.font_bold();
                font-size: 1.9rem;
                @include s.breakpoint(sp) {
                    font-size: 1.5rem;
                }
            }
        }

        .hashtag {
            color: #eb3a6c;
        }
        .example {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            @include s.breakpoint(sp) {
                gap: 0px;
                .img {
                    width: 100px;
                }
            }
        }
    }
    .main {
        font-size: 1.8rem;
        padding-top: 20px;
        padding-bottom: 50px;
        text-align: center;
        @include s.breakpoint(sp) {
            font-size: 1.4rem;
        }

        .head {
            @include s.font_bold();
            font-size: 3rem;
            margin-top: 60px;
            margin-bottom: 30px;
            @include s.breakpoint(sp) {
                font-size: 2rem;
                margin-top: 40px;
                margin-bottom: 20px;
            }
        }

        .card {
            position: relative;
            flex-direction: column;
            max-width: 700px;
            margin: 50px auto;
            padding: 30px;
            background-color: #ffffff;
            box-shadow: 2px 2px 4px 0 gray;
            border-radius: 30px;
            @include s.breakpoint(sp) {
                margin: 40px auto;
                padding: 10px;
                border-radius: 20px;
            }

            .bubble {
                position: absolute;
                top: 30px;
                left: 30px;
                width: 173px;
                @include s.breakpoint(sp) {
                    top: 10px;
                    left: 10px;
                    width: 90px;
                }
            }

            .area {
                margin-top: 120px;
                margin-bottom: 50px;
                @include s.breakpoint(sp) {
                    margin-top: 70px;
                }
            }

            .flow {
                display: flex;
                justify-content: space-around;
                align-items: center;
                gap: 20px;
                margin-bottom: 50px;
                @include s.breakpoint(sp) {
                    flex-direction: column-reverse;
                    gap: 15px;
                    margin-bottom: 70px;
                }

                div {
                    position: relative;
                    width: 320px;
                    @include s.breakpoint(sp) {
                        width: initial;
                        max-width: 330px;
                    }

                    .title {
                        @include s.font_bold();
                    }

                    .triangle {
                        position: absolute;
                        transform: translateX(-50%);
                        left: 50%;
                        top: calc(100% + 40px);
                        width: 0;
                        height: 0;
                        border-left: 30px solid transparent;
                        border-right: 30px solid transparent;
                        border-top: 25px solid #ffcc00;
                        @include s.breakpoint(sp) {
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-top: 15px solid #ffcc00;
                            top: calc(100% + 25px);
                        }
                    }
                }

                img {
                    width: 222px;
                    @include s.breakpoint(sp) {
                        width: 200px;
                    }
                }
            }
        }

        .voice {
            margin-top: 130px;
            @include s.breakpoint(sp) {
                margin-top: 70px;
            }

            .voice-list {
                margin: 0px 100px;
                text-align: center;
                @include s.breakpoint(tablet) {
                    margin: 0px 30px;
                }
                @include s.breakpoint(sp) {
                    margin: 0px 10px;
                }
            }

            .voice-item {
                display: flex;
                margin-bottom: 50px;
                @include s.breakpoint(sp) {
                    flex-direction: column;
                }
            }

            .voice-item-img {
                order: 0;
                flex-shrink: 0;
                img {
                    width: 125px;
                    margin-bottom: 5px;
                }
                p {
                    @include s.font_bold();
                }
            }

            .balloon-left {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                height: auto;
                background: #ebebeb;
                margin-left: 50px;
                padding: 30px 20px;
                text-align: left;
                border-radius: 15px;
                @include s.breakpoint(sp) {
                    order: 1;
                    margin-top: 10px;
                    margin-left: 0;
                    padding: 15px;
                }

                &::after {
                    content: "";
                    border: solid transparent;
                    height: 0;
                    width: 0;
                    pointer-events: none;
                    position: absolute;
                    border-color: rgba(90, 230, 40, 0);
                    border-top-width: 10px;
                    border-bottom-width: 10px;
                    border-left-width: 30px;
                    border-right-width: 30px;
                    margin-top: -10px;
                    border-right-color: #ebebeb;
                    right: 100%;
                    top: 20%;
                    @include s.breakpoint(sp) {
                        display: none;
                    }
                }
            }

            .balloon-right {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                height: auto;
                background: #ebebeb;
                margin-right: 50px;
                padding: 30px 20px;
                text-align: left;
                border-radius: 15px;
                @include s.breakpoint(sp) {
                    order: 1;
                    margin-top: 10px;
                    margin-right: 0;
                    padding: 15px;
                }

                &::after {
                    content: "";
                    border: solid transparent;
                    height: 0;
                    width: 0;
                    pointer-events: none;
                    position: absolute;
                    border-color: rgba(90, 230, 40, 0);
                    border-top-width: 10px;
                    border-bottom-width: 10px;
                    border-left-width: 30px;
                    border-right-width: 30px;
                    margin-top: -10px;
                    border-left-color: #ebebeb;
                    left: 100%;
                    top: 20%;
                    @include s.breakpoint(sp) {
                        display: none;
                    }
                }
            }
        }

        .detail {
            margin-top: 130px;
            @include s.breakpoint(sp) {
                margin-top: 70px;
            }

            .detail-item {
                border: 1px solid gray;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 40px;
                max-width: 550px;
                background-color: #fff;
                box-shadow: 2px 2px 4px 0 gray;
                border-radius: 15px;
                @include s.breakpoint(sp) {
                    margin-bottom: 30px;
                }

                span {
                    @include s.font_bold();
                    display: block;
                    background: #ff7da2;
                    color: #fff;
                    text-align: center;
                    border-top-left-radius: 14px;
                    border-top-right-radius: 14px;
                    font-size: 2rem;
                    padding: 5px 0;

                    @include s.breakpoint(sp) {
                        font-size: 1.6rem;
                    }
                }
            }

            .inner {
                text-align: center;
                padding: 15px 10px;

                .sup {
                    font-size: 1.6rem;
                    @include s.breakpoint(sp) {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        .entry {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .catch {
                @include s.font_bold();
                font-size: 2.2rem;
                margin-top: 90px;
                margin-bottom: 40px;
                @include s.breakpoint(sp) {
                    font-size: 1.7rem;
                    margin-top: 70px;
                    margin-bottom: 30px;
                }
            }
            .foot {
                max-width: 550px;
                font-size: 1.4rem;
                margin-top: 50px;
                @include s.breakpoint(sp) {
                    font-size: 1.2rem;
                    margin-top: 40px;
                }
            }
        }
    }
}
