@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.c-privacy {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    &__contents {
        padding-bottom: 65px;
        @include s.breakpoint(sp) {
            padding-bottom: 20px;
        }
        h4 {
            @include s.font_bold();
            margin-bottom: 20px;
            line-height: calc(22 / 14);
            color: s.$c-primary;
            margin-top: 60px;
        }
        p {
            & + p {
                margin-top: 30px;
            }
        }
    }
    &__contact {
        text-align: center;
        margin-top: 50px;
        h3 {
            margin-bottom: 0;
            line-height: 100%;
        }
        p {
            @include s.font_bold();
            margin-top: 20px;
            line-height: 100%;
        }
        .o-button {
            margin: 20px auto 0;
        }
    }
}
