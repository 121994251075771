@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.c-programming-school {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    .o-contentsTitle {
        margin-bottom: 40px;
    }
    &__container {
        & + & {
            margin-top: 80px;
            @include s.breakpoint(sp) {
                margin-top: 50px;
            }
        }
        p {
            &.bold {
                @include s.font_bold();
            }
        }
        ul {
            & + ul {
                margin-top: 20px;
                @include s.breakpoint(sp) {
                    margin-top: 15px;
                }
            }
            li {
                position: relative;
                padding-left: 15px;
                & + li {
                    margin-top: 5px;
                }
                &::before {
                    content: "";
                    display: inline-block;
                    margin-right: 6px;
                    width: 7px;
                    height: 7px;
                    background-color: #dc6190;
                    border-radius: 50%;
                    position: absolute;
                    top: 7px;
                    left: 0;
                }
                a {
                    color: s.$c-primary;
                    text-decoration: underline;
                }
                &.iconNone {
                    &::before {
                        content: none;
                    }
                }
            }
        }
    }
    &__flex {
        display: flex;
        justify-content: space-between;
        @include s.breakpoint(sp) {
            flex-direction: column;
        }
        &--center {
            margin-top: 30px;
            justify-content: center;
            .box {
                margin: 0 20px;
                @include s.breakpoint(sp) {
                    margin: 0;
                    & + .box {
                        @include s.breakpoint(sp) {
                            margin-top: 30px;
                        }
                    }
                }
                .image {
                    text-align: center;
                    margin-bottom: 25px;
                }
                h4 {
                    @include s.font_bold();
                    margin-bottom: 5px;
                    span {
                        @include s.font_regular();
                    }
                }
            }
        }
        &--between {
            .left {
                text-align: center;
                min-width: 280px;
                @include s.breakpoint(sp) {
                    min-width: auto;
                }
            }
            .right {
                padding-left: 35px;
                @include s.breakpoint(sp) {
                    padding-left: 0;
                    margin-top: 30px;
                }
                ul {
                    margin-top: 25px;
                }
            }
        }
    }
    &__contact {
        .block {
            text-align: center;
            & + .block {
                margin-top: 30px;
            }
            h4 {
                @include s.font_bold();
            }
            a {
                color: s.$c-primary;
                text-decoration: underline;
            }
        }
    }
}
