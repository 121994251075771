@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.c-report {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    .l-contents {
        padding-bottom: 20px;
    }
    &__wrapper {
        padding: 0 40px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include s.breakpoint(tablet) {
            padding: 0;
        }
        @include s.breakpoint(sp) {
            flex-direction: column;
        }
        &::after {
            content: "";
            display: block;
            width: 30%;
        }
    }
    &__block {
        width: 30%;
        margin-bottom: 60px;
        @include s.breakpoint(sp) {
            width: 100%;
            margin-bottom: 0;
            & + & {
                margin-top: 60px;
            }
        }
        .inner {
            max-width: 172px;
            margin: 0 auto;
            @include s.breakpoint(tablet) {
                max-width: 100%;
                margin: 0;
            }
        }
        h4 {
            @include s.font_bold();
            line-height: 100%;
            margin-bottom: 15px;
            &::after {
                content: "";
                display: block;
                width: 100%;
                max-width: 172px;
                height: 1px;
                @include m.gradationOrange();
                margin-top: 6px;
            }
        }
        ul {
            li {
                line-height: 100%;
                position: relative;
                padding-left: 25px;
                & + li {
                    margin-top: 15px;
                }
                &::before {
                    content: "";
                    display: inline-block;
                    margin-right: 6px;
                    width: 7px;
                    height: 7px;
                    background-color: #dc6190;
                    border-radius: 50%;
                    position: absolute;
                    top: 4px;
                    left: 9px;
                }
                a {
                    display: inline-flex;
                    align-items: center;
                    &::after {
                        content: "";
                        display: inline-block;
                        width: 14px;
                        height: 16px;
                        background-image: url(../img/report/pdf-icon.png);
                        background-repeat: no-repeat;
                        background-size: contain;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
