@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.o-breadcrumbs {
    overflow: hidden;
    background: linear-gradient(-90deg, #ffffff, #fce2d4 50%, #f9c7ce);
    display: inline-block;
    padding: 8px 15% 8px 20px;
    @include s.breakpoint(wideScreen) {
        display: block;
        padding: 8px 20px;
    }
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    .o-inner {
        padding: 0;
        @include s.breakpoint(wideScreen) {
            padding: 0 20px;
        }
    }
    ul {
        display: inline-flex;
        align-items: center;
    }
    li {
        display: flex;
        align-items: center;
        // white-space: nowrap;
        & + li {
            margin-left: 10px;
            &::before {
                content: "";
                display: inline-block;
                margin-right: 10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 2px 0 2px 6px;
                border-color: transparent transparent transparent #d3166b;
            }
        }
        a {
            color: s.$c-white;
            font-size: 1.1rem;
            line-height: 100%;
            @include s.font_bold();
        }
        &:last-child {
            a {
                color: s.$c-black;
            }
        }
    }
}
