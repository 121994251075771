@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.o-title {
    text-align: center;
    margin-bottom: 40px;
    @include s.font_bold();
    line-height: 100%;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    span {
        color: s.$c-primary;
    }
    &::after {
        content: "";
        display: block;
        width: 100%;
        max-width: 100px;
        height: 2px;
        margin: 10px auto 0;
        @include m.gradationPink();
    }
}

.o-contentsTitle {
    @include s.font_bold();
    text-align: center;
    font-size: 1.7rem;
    line-height: calc(18 / 17);
    color: s.$c-primary;
    line-height: 100%;
    margin-bottom: 50px;
    @include s.breakpoint(sp) {
        line-height: 1.5;
        margin-bottom: 30px;
    }
}
