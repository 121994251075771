@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.c-about {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    &__block {
        & + & {
            margin-top: 40px;
            @include s.breakpoint(sp) {
                margin-top: 30px;
            }
        }
        h4 {
            color: s.$c-primary;
            line-height: 100%;
            @include s.font_bold();
            padding-bottom: 25px;
            @include s.breakpoint(sp) {
                padding-bottom: 15px;
            }
        }
        .o-table {
            width: 100%;
            table {
                width: 100%;
                th {
                    vertical-align: middle;
                }
            }
        }
        p {
            line-height: calc(30 / 14);
        }
        a {
            color: s.$c-primary;
            text-decoration: underline;
        }
    }
    &__businessNameList {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
            width: 18%;
            margin-bottom: 25px;
            text-align: center;
            @include s.breakpoint(tablet) {
                width: 31%;
            }
            @include s.breakpoint(sp) {
                width: 48%;
            }
            img {
                mix-blend-mode: multiply;
            }
            p {
                margin-top: 15px;
                line-height: calc(18 / 14);
            }
            &.dammy {
                display: block;
                width: 18%;
                @include s.breakpoint(tablet) {
                    width: 31%;
                }
                @include s.breakpoint(sp) {
                    display: none;
                }
            }
        }
    }
    &__imageCenter {
        text-align: center;
        padding-bottom: 30px;
        &--pbNone {
            padding-bottom: 0;
        }
    }
    &__sdgsBlock {
        margin-top: 40px;
        margin-bottom: 60px;
    }
    &__sdgsBox {
        margin-bottom: 30px;
        .dot {
            position: relative;
            background-color: #faa5c5;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            margin: 0 auto 20px;
            min-width: auto;
            min-height: auto;
            &::before,
            &::after {
                position: absolute;
                content: "";
                display: inline-block;
                background-color: #faa5c5;
                width: 7px;
                height: 7px;
                border-radius: 50%;
            }
            &::before {
                left: -18px;
            }
            &::after {
                right: -18px;
            }
        }
        h5 {
            @include s.font_bold();
            text-align: center;
            padding-bottom: 15px;
        }
        ul {
            display: flex;
            justify-content: center;
            @include s.breakpoint(sp) {
                flex-wrap: wrap;
                justify-content: space-between;
                &::after {
                    content: "";
                    display: block;
                    width: 30%;
                }
            }
            li {
                margin: 0 10px;
                max-width: 90px;
                @include s.breakpoint(sp) {
                    text-align: center;
                    max-width: 100%;
                    width: 30%;
                    margin: 0 0 10px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    &__category {
        display: flex;
        justify-content: center;
        @include s.breakpoint(sp) {
            justify-content: space-between;
        }
        h5 {
            width: 102px;
            @include s.font_bold();
            text-align: center;
            line-height: 100%;
            margin: 0 8px;
            @include s.breakpoint(sp) {
                font-size: 1.2rem;
                width: 30%;
            }
            &::after {
                content: "";
                display: block;
                margin-top: 8px;
                width: 100%;
                height: 2px;
            }
            &:nth-child(1) {
                &::after {
                    @include m.gradationRed();
                }
            }
            &:nth-child(2) {
                &::after {
                    @include m.gradationOrange();
                }
            }
            &:nth-child(3) {
                &::after {
                    @include m.gradationGreen();
                }
            }
        }
    }
    &__summary {
        margin-top: 50px;
        max-height: 500px;
        overflow-y: scroll;
        .block {
            display: flex;
            justify-content: space-between;
            & + .block {
                margin-top: 25px;
            }
            h5 {
                width: 10%;
                min-width: 90px;
                @include s.font_bold();
                text-align: center;
                font-size: 1.2rem;
                line-height: calc(24 / 14);
                display: flex;
                flex-direction: column;
                &::after {
                    content: "";
                    display: block;
                    margin-top: auto;
                    width: 100%;
                    height: 2px;
                    @include m.gradationRed();
                }
                &.green {
                    &::after {
                        @include m.gradationGreen();
                    }
                }
                &.blue {
                    &::after {
                        @include m.gradationBlue();
                    }
                }
                &.orange {
                    &::after {
                        @include m.gradationOrange();
                    }
                }
                &.gray {
                    &::after {
                        background: #e9e9e9;
                    }
                }
            }
            ul {
                width: 90%;
                padding-left: 15px;
                &::after {
                    content: "";
                    display: block;
                    margin-top: 25px;
                    width: 100%;
                    height: 2px;
                    background-color: #e9e9e9;
                }
                li {
                    line-height: calc(24 / 14);
                    position: relative;
                    padding-left: 20px;
                    & + li {
                        margin-top: 25px;
                    }
                    &::before {
                        content: "";
                        display: inline-block;
                        margin-right: 6px;
                        width: 7px;
                        height: 7px;
                        background-color: #dc6190;
                        border-radius: 50%;
                        position: absolute;
                        top: 9px;
                        left: 0;
                    }
                    &.green {
                        &::before {
                            background-color: #38b72a;
                        }
                    }
                    &.blue {
                        &::before {
                            background-color: #4e81f4;
                        }
                    }
                    &.orange {
                        &::before {
                            background-color: #ffac7f;
                        }
                    }
                }
            }
        }
    }
    &__button {
        padding: 40px 0;
        .o-button {
            margin: 0 auto;
            color: s.$c-white;
            text-decoration: none;
        }
    }
}
