@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.o-hamburger {
    display: none;
    @include s.breakpoint(tablet) {
        display: block;
        position: fixed;
        z-index: 999;
        top: 15px;
        right: 15px;
        width: 45px;
        height: 45px;
        @include m.gradationPink();
        border: 2px solid s.$c-primary;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: 0.3s all ease-in-out;
        &:hover {
            cursor: pointer;
        }
        span {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 2px;
            background-color: s.$c-white;
            transition: 0.3s all ease-in-out;
            & + span {
                margin-top: 5px;
                transition: 0.3s all ease-in-out;
            }
        }
        &.is_active {
            span {
                &:nth-child(1) {
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    display: none;
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
                & + span {
                    margin-top: 0;
                    top: -2px;
                }
            }
        }
    }
}
