/* system
--------------------------- */

// color system
// ---------------------
$c-white: #ffffff;
$c-black: #000000;
$c-pink: #e8a0ba;
$c-gray: #f9f9f9;
$c-green: #38b72a;
$c-blue: #4e81f4;
$c-orange: #ff8400;
$c-primary: #d72072;

// transition
// ---------------------
@mixin transition-base($second: 0.3s) {
    transition: all $second ease-in-out;
}

// content max-width
// ---------------------
@mixin content-maxwidth($px: 1200px) {
    max-width: $px;
}

// font system
// ---------------------
@mixin notosansjp() {
    font-family: "Noto Sans JP", sans-serif;
}

// font-weight system
// ---------------------
@mixin font_regular($weight: 400) {
    font-weight: $weight;
}
@mixin font_bold($weight: 700) {
    font-weight: $weight;
}

// Google Font Awesome
// ---------------------
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

// responsive system
// ---------------------
@mixin breakpoint($point) {
    @if $point == wideScreen {
        @media (min-width: 1441px) {
            @content;
        }
    }
    @if $point == tablet {
        @media (max-width: 1024px) {
            @content;
        }
    }
    @if $point == sp {
        @media (max-width: 599px) {
            @content;
        }
    }
}

// utils
.pc-only {
    display: inline-block;
    @include breakpoint(tablet) {
        display: none;
    }
    @include breakpoint(sp) {
        display: none;
    }
}
.tablet-only {
    display: none;
    @include breakpoint(tablet) {
        display: inline-block;
    }
    @include breakpoint(sp) {
        display: none;
    }
}
.sp-only {
    display: none;
    @include breakpoint(tablet) {
        display: none;
    }
    @include breakpoint(sp) {
        display: inline-block;
    }
}
