@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.l-main {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    &--underLayer {
        margin-top: 80px;
        @include s.breakpoint(sp) {
            margin-top: 50px;
        }
    }
}
