@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.c-top {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    &__main {
        padding: 350px 20px 370px;
        max-width: 1100px;
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(../img/top/bgCircle.png);
        background-repeat: repeat-y;
        background-size: contain;
        @include s.breakpoint(tablet) {
            padding-top: 270px;
        }
        .mainArea {
            background-color: #ff9699;
            border-radius: 50%;
            width: 360px;
            height: 360px;
            text-align: center;
            padding: 50px;
            position: relative;
            z-index: 10;
            @include s.breakpoint(tablet) {
                width: 260px;
                height: 260px;
                padding: 30px;
            }
            .brandBlock {
                .image {
                    img {
                        max-width: 200px;
                        @include s.breakpoint(tablet) {
                            max-width: 120px;
                        }
                    }
                }
                p {
                    margin-top: 15px;
                    font-size: 1.3rem;
                    line-height: calc(25 / 14);
                    @include s.font_bold();
                    color: s.$c-white;
                    @include s.breakpoint(tablet) {
                        font-size: 1.2rem;
                    }
                }
                a {
                    background-color: #d72072;
                    margin: 15px auto 0;
                    border-radius: 25px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    @include s.font_bold();
                    color: s.$c-white;
                    padding: 8px 20px;
                    @include s.breakpoint(tablet) {
                        font-size: 1.2rem;
                    }
                }
            }
        }
        .bubbleArea {
            .bubblePicture {
                position: absolute;
                z-index: 5;
                background-color: #ffefef;
                border-radius: 50%;
                width: 140px;
                height: 140px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 5px;
                @media screen and (max-width: 650px) {
                    display: none;
                }
                @include s.transition-base();
                @include s.breakpoint(tablet) {
                    width: 90px;
                    height: 90px;
                }
                img {
                    position: relative;
                    z-index: 10;
                    //opacity:0.5;
                    @include s.breakpoint(tablet) {
                        max-width: 80px;
                    }
                }
                &:nth-child(8) {
                    top: 73%;
                    left: 1%;
                    transform-origin: center bottom;
                    animation: yurayura01 5.2s ease-in-out infinite;
                    @include s.breakpoint(sp) {
                        top: 0;
                    }
                    @keyframes yurayura01 {
                        0% {
                            transform: translate(0, 0);
                        }
                        25% {
                            transform: translate(5px, 5px);
                        }
                        75% {
                            transform: translate(-5px, 5px);
                        }
                        100% {
                            transform: translateY(0, 0);
                        }
                    }
                }
                &:nth-child(9) {
                    top: 30%;
                    right: 0%;
                    transform-origin: center bottom;
                    animation: yurayura01 5.5s ease-in-out infinite;
                    @include s.breakpoint(sp) {
                        top: 0;
                    }
                    @keyframes yurayura01 {
                        0% {
                            transform: translate(0, 0);
                        }
                        25% {
                            transform: translate(5px, 5px);
                        }
                        75% {
                            transform: translate(-5px, 5px);
                        }
                        100% {
                            transform: translateY(0, 0);
                        }
                    }
                }
                &:nth-child(10) {
                    top: 5%;
                    left: 1%;
                    transform-origin: center bottom;
                    animation: yurayura01 5.7s ease-in-out infinite;
                    @include s.breakpoint(sp) {
                        top: 0;
                    }
                    @keyframes yurayura01 {
                        0% {
                            transform: translate(0, 0);
                        }
                        25% {
                            transform: translate(5px, 5px);
                        }
                        75% {
                            transform: translate(-5px, 5px);
                        }
                        100% {
                            transform: translateY(0, 0);
                        }
                    }
                }
            }
            .bubbleBox {
                position: absolute;
                z-index: 5;
                background-color: #ffefef;
                border-radius: 50%;
                width: 180px;
                height: 180px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 5px;
                @include s.transition-base();
                @include s.breakpoint(tablet) {
                    width: 120px;
                    height: 120px;
                }
                .border {
                    transition: all 1s ease-in-out;
                    opacity: 0;
                    visibility: hidden;
                    position: relative;
                    width: 200px;
                    height: 1px;
                    background-color: #db357f;
                    @include s.breakpoint(tablet) {
                        width: 225px;
                    }
                }
                img {
                    position: relative;
                    z-index: 10;
                    @include s.breakpoint(tablet) {
                        max-width: 80px;
                    }
                }
                &:nth-child(1) {
                    top: 10%;
                    left: 35%;
                    transform-origin: center bottom;
                    animation: yurayura01 5s ease-in-out infinite;
                    @include s.breakpoint(sp) {
                        top: 0;
                    }
                    @keyframes yurayura01 {
                        0% {
                            transform: translate(0, 0);
                        }
                        25% {
                            transform: translate(5px, 5px);
                        }
                        75% {
                            transform: translate(-5px, 5px);
                        }
                        100% {
                            transform: translateY(0, 0);
                        }
                    }
                    .border {
                        top: 140px;
                        left: 30px;
                        transform: rotate(65deg);
                        @include s.breakpoint(sp) {
                            transform: rotate(95deg);
                            top: 150px;
                            left: 0;
                        }
                    }
                }
                &:nth-child(2) {
                    top: 15%;
                    right: 15%;
                    transform-origin: center bottom;
                    animation: yurayura02 6s ease-in-out infinite;
                    @include s.breakpoint(sp) {
                        right: 5%;
                    }
                    @keyframes yurayura02 {
                        0% {
                            transform: translate(0, 0);
                        }
                        25% {
                            transform: translate(-5px, 5px);
                        }
                        75% {
                            transform: translate(5px, -5px);
                        }
                        100% {
                            transform: translateY(0, 0);
                        }
                    }
                    .border {
                        top: 130px;
                        left: -110px;
                        transform: rotate(-45deg);
                        @include s.breakpoint(sp) {
                            left: -70px;
                        }
                    }
                }
                &:nth-child(3) {
                    top: 45%;
                    right: 10%;
                    transform-origin: center bottom;
                    animation: yurayura03 5s ease-in-out infinite;
                    @include s.breakpoint(sp) {
                        top: 60%;
                        right: 5%;
                    }
                    @keyframes yurayura03 {
                        0% {
                            transform: translate(0, 0);
                        }
                        25% {
                            transform: translate(5px, 5px);
                        }
                        75% {
                            transform: translate(-5px, -5px);
                        }
                        100% {
                            transform: translateY(0, 0);
                        }
                    }
                    .border {
                        top: -10px;
                        left: -120px;
                        transform: rotate(15deg);
                        @include s.breakpoint(sp) {
                            top: -70px;
                            left: -40px;
                            transform: rotate(60deg);
                        }
                    }
                }
                &:nth-child(4) {
                    top: 70%;
                    right: 20%;
                    transform-origin: center bottom;
                    animation: yurayura04 5.5s ease-in-out infinite;
                    @include s.breakpoint(sp) {
                        top: 73%;
                        right: 20%;
                    }
                    @keyframes yurayura04 {
                        0% {
                            transform: translate(0, 0);
                        }
                        25% {
                            transform: translate(-5px, -5px);
                        }
                        75% {
                            transform: translate(5px, 5px);
                        }
                        100% {
                            transform: translateY(0, 0);
                        }
                    }
                    .border {
                        top: -100px;
                        left: -90px;
                        transform: rotate(55deg);
                        @include s.breakpoint(sp) {
                            left: -20px;
                            transform: rotate(85deg);
                        }
                    }
                }
                &:nth-child(5) {
                    top: 65%;
                    left: 30%;
                    transform-origin: center bottom;
                    animation: yurayura05 6.5s ease-in-out infinite;
                    @include s.breakpoint(sp) {
                        top: 72%;
                        left: 5%;
                    }
                    @keyframes yurayura05 {
                        0% {
                            transform: translate(0, 0);
                        }
                        25% {
                            transform: translate(5px, -5px);
                        }
                        75% {
                            transform: translate(-5px, 5px);
                        }
                        100% {
                            transform: translateY(0, 0);
                        }
                    }
                    .border {
                        top: -50px;
                        left: 20px;
                        transform: rotate(-65deg);
                        @include s.breakpoint(sp) {
                            top: -100px;
                            left: 60px;
                            transform: rotate(-65deg);
                        }
                    }
                }
                &:nth-child(6) {
                    top: 45%;
                    left: 10%;
                    transform-origin: center bottom;
                    animation: yurayura06 6.5s ease-in-out infinite;
                    @include s.breakpoint(sp) {
                        top: 57%;
                        left: 3%;
                    }
                    @keyframes yurayura06 {
                        0% {
                            transform: translate(0, 0);
                        }
                        25% {
                            transform: translate(5px, 5px);
                        }
                        75% {
                            transform: translate(-5px, -5px);
                        }
                        100% {
                            transform: translateY(0, 0);
                        }
                    }
                    .border {
                        top: 20px;
                        left: 110px;
                        transform: rotate(-5deg);
                        @include s.breakpoint(sp) {
                            top: -30px;
                            left: 50px;
                            transform: rotate(-45deg);
                        }
                    }
                }
                &:nth-child(7) {
                    top: 20%;
                    left: 15%;
                    transform-origin: center bottom;
                    animation: yurayura07 5.5s ease-in-out infinite;
                    @include s.breakpoint(sp) {
                        top: 15%;
                        left: 3%;
                    }
                    @keyframes yurayura07 {
                        0% {
                            transform: translate(0, 0);
                        }
                        25% {
                            transform: translate(-5px, -5px);
                        }
                        75% {
                            transform: translate(-5px, -5px);
                        }
                        100% {
                            transform: translateY(0, 0);
                        }
                    }
                    .border {
                        top: 110px;
                        left: 100px;
                        transform: rotate(45deg);
                        @include s.breakpoint(sp) {
                            top: 70px;
                            left: 30px;
                        }
                    }
                }
                &:hover {
                    background-color: #db357f;
                    cursor: pointer;
                    .border {
                        opacity: 1;
                        visibility: visible;
                    }
                    @include s.breakpoint(sp) {
                        background-color: inherit;
                    }
                }
                &.is_active {
                    background-color: #db357f;
                    .border {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
    &__message {
        position: relative;
        padding: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        .swiper-container {
            position: absolute;
            top: 0;
            z-index: 5;
            .swiper-wrapper {
                transition-timing-function: linear;
            }
            .swiper-slide {
                height: 615px !important; /* 高さを指定 */
                width: auto !important;
                img {
                    width: auto;
                    height: 100%;
                }
            }
        }
        .block {
            position: relative;
            z-index: 10;
            background-color: rgba($color: s.$c-white, $alpha: 0.9);
            padding: 30px 30px 30px;
            max-width: 660px;
            margin: 0 20px;
            h3 {
                text-align: center;
                font-size: 3rem;
                letter-spacing: 0.1em;
                line-height: calc(46 / 30);
                color: s.$c-primary;
                text-shadow: 2px 2px 0 s.$c-white, -2px -2px 0 s.$c-white,
                    -2px 2px 0 s.$c-white, 2px -2px 0 s.$c-white,
                    0px 2px 0 s.$c-white, 0 -2px 0 s.$c-white,
                    -2px 0 0 s.$c-white, 2px 0 0 s.$c-white;
                box-shadow: 0 0 4px rgba($color: s.$c-white, $alpha: 0.5);
                @include s.breakpoint(sp) {
                    font-size: 2rem;
                }
            }
            p {
                text-align: center;
                margin-top: 50px;
                font-size: 1.8rem;
                letter-spacing: 0.1em;
                line-height: calc(30 / 18);
                text-shadow: 2px 2px 0 s.$c-white, -2px -2px 0 s.$c-white,
                    -2px 2px 0 s.$c-white, 2px -2px 0 s.$c-white,
                    0px 2px 0 s.$c-white, 0 -2px 0 s.$c-white,
                    -2px 0 0 s.$c-white, 2px 0 0 s.$c-white;
                box-shadow: 0 0 4px rgba($color: s.$c-white, $alpha: 0.5);
                @include s.breakpoint(sp) {
                    margin-top: 25px;
                    font-size: 1.6rem;
                }
                & + p {
                    margin-top: 45px;
                    @include s.breakpoint(sp) {
                        margin-top: 15px;
                    }
                }
            }
            .logo {
                margin-top: 30px;
                text-align: center;
                @include s.breakpoint(sp) {
                    margin-top: 20px;
                }
            }
            .shake_animation {
                animation: 5s linear infinite rotation;

                @keyframes rotation {
                    0% {
                        transform: rotate(0deg);
                    }
                    1% {
                        transform: rotate(23deg);
                    }
                    2% {
                        transform: rotate(-21deg);
                    }
                    4% {
                        transform: rotate(13deg);
                    }
                    6% {
                        transform: rotate(-10deg);
                    }
                    7% {
                        transform: rotate(2deg);
                    }
                    8% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }
    &__news {
        margin-top: 100px;
        .button {
            margin-top: 60px;
            @include s.breakpoint(sp) {
                margin-top: 40px;
            }
            .o-button {
                margin: 0 auto;
            }
        }
    }
}
