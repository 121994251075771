@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.c-newsDetail {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    &__article {
        position: relative;
        padding: 60px 35px 25px;
        @include s.breakpoint(sp) {
            padding: 60px 20px 10px;
        }
        time {
            position: absolute;
            top: 0;
            right: 0;
            @include m.gradationPink();
            line-height: 100%;
            padding: 13px 20px;
        }
        h3 {
            color: s.$c-primary;
            font-size: 1.7rem;
            line-height: calc(18 / 17);
            @include s.font_bold();
        }
        .contents {
            margin-top: 50px;
            line-height: calc(22 / 14);
            .image {
                text-align: center;
                padding-bottom: 40px;
                p {
                    margin-top: 10px;
                    padding-bottom: 0;
                }
            }
            h4 {
                padding-bottom: 30px;
                @include s.font_bold();
                &.primary {
                    color: s.$c-primary;
                }
            }
            p {
                padding-bottom: 35px;
            }
            a {
                color: s.$c-primary;
                text-decoration: underline;
                word-break: break-all;
            }
            .list {
                padding-bottom: 30px;
                li {
                    position: relative;
                    padding-left: 20px;
                    & + li {
                        margin-top: 10px;
                    }
                    &::before {
                        content: "";
                        display: inline-block;
                        margin-right: 6px;
                        width: 12px;
                        height: 12px;
                        background-color: s.$c-primary;
                        border-radius: 50%;
                        position: absolute;
                        top: 5px;
                        left: 0;
                    }
                }
            }
            .o-table {
                padding-bottom: 40px;
            }
            .labels {
                li {
                    background-color: s.$c-pink;
                    padding: 5px 20px;
                    color: s.$c-white;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.2rem;
                    line-height: 100%;
                    border-radius: 10px;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    @include s.font_bold();
                }
            }
        }
    }
    &__pagination {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        .prev,
        .next {
            a {
                @include s.font_bold();
                color: s.$c-primary;
            }
        }
        .prev {
            &::before {
                content: "";
                display: inline-block;
                border-radius: 0;
                background: none;
                width: 0;
                height: 0;
                border-style: solid;
                margin-right: 10px;
                border-width: 6px 13px 6px 0;
                border-color: transparent #d3166b transparent transparent;
                @include s.breakpoint(sp) {
                    margin-right: 5px;
                }
            }
            &.linkNone {
                a {
                    pointer-events: none;
                    opacity: 0.6;
                }
                &::before {
                    opacity: 0.6;
                }
            }
        }
        .next {
            &::after {
                content: "";
                display: inline-block;
                border-radius: 0;
                background: none;
                width: 0;
                height: 0;
                border-style: solid;
                margin-left: 10px;
                border-width: 6px 0 6px 13px;
                border-color: transparent transparent transparent #d3166b;
                @include s.breakpoint(sp) {
                    margin-left: 5px;
                }
            }
            &.linkNone {
                a {
                    pointer-events: none;
                    opacity: 0.6;
                }
                &::after {
                    opacity: 0.6;
                }
            }
        }
        .back {
            display: inline-block;
            @include m.gradationOrange();
            @include m.gradationHover();
            border-radius: 4px;
            padding: 8px 16px;
            line-height: 100%;
            @include s.font_bold();
            @include s.breakpoint(sp) {
                padding: 8px;
            }
            a {
                color: s.$c-white;
            }
        }
    }
}
