@use "../foundation/system" as s;

@mixin gradationPink() {
    background: linear-gradient(90deg, #fce2d4 25%, #f9c7ce);
}

@mixin gradationOrange() {
    background: linear-gradient(90deg, #ffac7f 25%, #ff8092);
}

@mixin gradationRed() {
    background: linear-gradient(90deg, #e1769d 25%, #d72072);
}

@mixin gradationGreen() {
    background: linear-gradient(90deg, #89d06a 25%, #38b72a);
}

@mixin gradationBlue() {
    background: linear-gradient(90deg, #83a9ff 25%, #4e81f4);
}

@mixin gradationOrange2() {
    background: linear-gradient(90deg, #ffb565 25%, #ff8400);
}

@mixin gradationHover() {
    background-position: 1% 50%;
    background-size: 100% auto;
    &:hover {
        background-position: 99% 50%;
        background-size: 250% auto;
    }
}
