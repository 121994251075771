@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.c-lecture-inspection {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    &__block {
        & + & {
            margin-top: 70px;
            @include s.breakpoint(sp) {
                margin-top: 50px;
            }
        }
        h4 {
            color: s.$c-primary;
            line-height: 100%;
            @include s.font_bold();
            padding-bottom: 30px;
            @include s.breakpoint(sp) {
                padding-bottom: 20px;
            }
        }
        h5 {
            @include s.font_bold();
            padding-bottom: 25px;
            @include s.breakpoint(sp) {
                padding-bottom: 15px;
            }
            &.underline {
                &::after {
                    content: "";
                    display: block;
                    width: 172px;
                    height: 1px;
                    @include m.gradationOrange();
                    margin-top: 6px;
                }
            }
        }
        p {
            line-height: calc(24 / 14);
            padding-bottom: 35px;
            @include s.breakpoint(sp) {
                padding-bottom: 25px;
            }
        }
        ul {
            padding-bottom: 30px;
            padding-left: 15px;
            @include s.breakpoint(sp) {
                padding-bottom: 20px;
            }
            li {
                position: relative;
                padding-left: 20px;
                & + li {
                    margin-top: 10px;
                }
                &::before {
                    content: "";
                    display: inline-block;
                    margin-right: 6px;
                    width: 7px;
                    height: 7px;
                    background-color: #dc6190;
                    border-radius: 50%;
                    position: absolute;
                    top: 7px;
                    left: 0;
                }
                a {
                    color: s.$c-primary;
                    text-decoration: underline;
                }
            }
        }
    }
    &__request {
        h5 {
            text-align: center;
        }
        p {
            text-align: center;
        }
        .o-button {
            margin: 0 auto;
        }
    }
    &__flex {
        margin-top: 85px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include s.breakpoint(sp) {
            flex-direction: column;
        }
        .image {
            width: 37%;
            @include s.breakpoint(sp) {
                width: 100%;
                text-align: center;
            }
            p {
                text-align: center;
                margin-top: 25px;
                color: s.$c-primary;
                line-height: 100%;
                @include s.font_bold();
            }
        }
        .contents {
            width: 60%;
            @include s.breakpoint(sp) {
                width: 100%;
                margin-top: 30px;
            }
            h5 {
                padding-bottom: 15px;
            }
            p {
            }
            ul {
                padding-left: 0;
                padding-bottom: 0;
            }
        }
    }
    &__button {
        margin-top: 40px;
        .o-button {
            margin: 0 auto;
        }
    }
}
