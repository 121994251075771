/* base
--------------------------- */
@use "./system" as s;

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
html {
    font-size: 62.5%;
    line-height: 1.5;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}
body {
    color: s.$c-black;
    background-color: s.$c-white;
    letter-spacing: 0.1em;
    font-size: 1.4rem;
    @include s.notosansjp();
    @include s.font_regular();
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    canvas {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }
    &.is_stop {
        overflow-y: hidden;
    }
}
::selection {
    background-color: rgba($color: s.$c-black, $alpha: 0.7);
    color: s.$c-white;
}

img,
video,
object {
    width: auto;
    max-width: 100%;
    height: auto;
    border: none;
}
a {
    color: s.$c-black;
    text-decoration: none;
    @include s.transition-base();
    // カーソルは乗っているがクリックはされていない状態
    &:hover {
        img {
            opacity: 0.8;
            @include s.transition-base();
        }
    }
    // クリックされてから離されるまでの状態
    &:active {
    }
    // 未訪問リンク
    &:link {
    }
    // 訪問済みリンク
    &:visited {
    }
}
a,
label,
input,
button {
    @include s.transition-base();
}
ul {
    list-style: none;
}
