@use "./../foundation/system" as s;
@use "../foundation/mixin" as m;

.l-footer {
    padding-top: 125px;
    margin-top: auto;
    letter-spacing: 0.01em;
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
        padding-top: 80px;
    }
    &__top {
        @include m.gradationPink();
        padding: 40px;
        text-align: center;
        img {
            max-width: 192px;
            @include s.breakpoint(sp) {
                max-width: 150px;
            }
        }
    }
    &__middle {
        position: relative;
        padding: 30px 0;
        @include s.breakpoint(tablet) {
            display: none;
        }
    }
    &__list {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    &__item {
        font-size: 1.3rem;
        @include s.transition-base();
        @include s.font_bold();
        &::before {
            content: "";
            display: inline-block;
            margin-right: 10px;
            width: 6px;
            height: 7px;
            background-image: url(../img/common/nav/navIcon.png);
            background-repeat: no-repeat;
            background-size: contain;
        }
        &:hover {
            cursor: pointer;
            &::before {
                background-image: url(../img/common/nav/navIcon_on.png);
            }
        }
        &.button {
            &::before {
                content: none;
            }
        }
        &.arrow {
            &::after {
                content: none;
            }
            &.is_active {
                &::before {
                    background-image: url(../img/common/nav/navIcon_on.png);
                }
            }
        }
    }
    &__childNav {
        display: none;
        position: absolute;
        z-index: -999;
        bottom: 80px;
        left: 0;
        width: 100%;
        @include m.gradationPink();
        padding: 40px 0 10px;
        &.is_active {
            z-index: 15;
            .o-inner {
                position: relative;
                &::after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    z-index: 20;
                    bottom: -40px;
                    left: 9%;
                    right: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 9px 10px 9px;
                    border-color: s.$c-white transparent transparent transparent;
                }
            }
            &.js-childNavProductFooter {
                .o-inner {
                    &::after {
                        left: auto;
                        right: 9%;
                    }
                }
            }
        }
        .block {
            display: flex;
            & + .block {
                margin-top: 45px;
            }
            .label {
                margin-right: 35px;
                @include s.font_bold();
                line-height: 100%;
                font-size: 1.2rem;
                letter-spacing: 0.01em;
                width: 200px;
                min-width: 200px;
                height: 35px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: s.$c-white;
                @include m.gradationRed();
            }
            ul {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-top: 10px;
                width: 100%;
                &::after {
                    content: "";
                    display: block;
                    width: 32%;
                }
                li {
                    display: flex;
                    align-items: center;
                    width: 32%;
                    margin-bottom: 15px;
                    line-height: 100%;
                    font-size: 1.3rem;
                    @include s.font_bold();
                    &::before {
                        content: "";
                        display: inline-block;
                        margin-right: 10px;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: s.$c-primary;
                    }
                    &.displayNone {
                        display: none;
                    }
                }
            }
            &--green {
                .label {
                    @include m.gradationGreen();
                }
                ul {
                    li {
                        &::before {
                            background-color: s.$c-green;
                        }
                    }
                }
            }
            &--blue {
                .label {
                    @include m.gradationBlue();
                }
                ul {
                    li {
                        &::before {
                            background-color: s.$c-blue;
                        }
                    }
                }
            }
            &--orange {
                .label {
                    @include m.gradationOrange2();
                }
                ul {
                    li {
                        &::before {
                            background-color: s.$c-orange;
                        }
                    }
                }
            }
            &--product {
                ul {
                    justify-content: flex-start;
                    width: 100%;
                    li {
                    }
                }
            }
        }
        .flex {
            margin-top: 45px;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            .block {
                width: 48%;
                margin-bottom: 30px;
                & + .block {
                    margin-top: 0;
                }
                ul {
                    flex-direction: column;
                    li {
                        width: 100%;
                    }
                }
            }
        }
    }
    &__bottom {
        @include m.gradationPink();
        padding-top: 20px;
        padding-bottom: 20px;
        @include s.font_bold();
        .o-inner--wide {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .left {
            font-size: 1.1rem;
        }
        .right {
            font-size: 1.1rem;
            a {
                display: inline-block;
                & + a {
                    margin-left: 20px;
                    @include s.breakpoint(sp) {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
