@use "../foundation/system" as s;
@use "../foundation/mixin" as m;

.o-buttonBlock {
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
}

.o-button {
    @include m.gradationOrange();
    @include m.gradationHover();
    @include s.font_bold();
    display: flex;
    align-items: center;
    justify-content: center;
    color: s.$c-white;
    line-height: 100%;
    font-size: 1.3rem;
    border-radius: 25px;
    width: 240px;
    height: 50px;
    @include s.breakpoint(tablet) {
    }
    @include s.breakpoint(sp) {
    }
    &--min {
        width: 150px;
        height: 40px;
    }
}
