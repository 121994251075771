@charset "UTF-8";
/* system
--------------------------- */
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
/* reset
--------------------------- */
/* 
 * destyle.css v1.0.14
 * https://github.com/nicolas-cusan/destyle.css 
 */
* {
  box-sizing: border-box;
}

::after,
::before {
  box-sizing: inherit;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
}

main {
  display: block;
}

address,
blockquote,
dl,
figure,
form,
iframe,
p,
pre,
table {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-top: 1px solid;
  margin: 0;
  clear: both;
  color: inherit;
}

pre {
  font-family: monospace, monospace;
  font-size: inherit;
}

address {
  font-style: inherit;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: inherit;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  vertical-align: bottom;
}

embed,
iframe,
object {
  border: 0;
  vertical-align: bottom;
}

button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  border: 0;
  background: 0 0;
  padding: 0;
  margin: 0;
  outline: 0;
  border-radius: 0;
  text-align: inherit;
}

[type=checkbox] {
  -webkit-appearance: checkbox;
  appearance: checkbox;
}

[type=radio] {
  -webkit-appearance: radio;
  appearance: radio;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type=button],
[type=reset],
[type=submit],
button {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

[type=button][disabled],
[type=reset][disabled],
[type=submit][disabled],
button[disabled] {
  cursor: default;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

select::-ms-expand {
  display: none;
}

option {
  padding: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

label[for] {
  cursor: pointer;
}

details {
  display: block;
}

summary {
  display: list-item;
}

[contenteditable] {
  outline: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  text-align: left;
}

td,
th {
  vertical-align: top;
  padding: 0;
}

th {
  text-align: left;
  font-weight: 700;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

.pc-only {
  display: inline-block;
}
@media (max-width: 1024px) {
  .pc-only {
    display: none;
  }
}
@media (max-width: 599px) {
  .pc-only {
    display: none;
  }
}

.tablet-only {
  display: none;
}
@media (max-width: 1024px) {
  .tablet-only {
    display: inline-block;
  }
}
@media (max-width: 599px) {
  .tablet-only {
    display: none;
  }
}

.sp-only {
  display: none;
}
@media (max-width: 1024px) {
  .sp-only {
    display: none;
  }
}
@media (max-width: 599px) {
  .sp-only {
    display: inline-block;
  }
}

/* base
--------------------------- */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  line-height: 1.5;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  color: #000000;
  background-color: #ffffff;
  letter-spacing: 0.1em;
  font-size: 1.4rem;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
body.is_stop {
  overflow-y: hidden;
}

::selection {
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
}

img,
video,
object {
  width: auto;
  max-width: 100%;
  height: auto;
  border: none;
}

a {
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
a:hover img {
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}
a,
label,
input,
button {
  transition: all 0.3s ease-in-out;
}

ul {
  list-style: none;
}

.l-header {
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #ffffff;
  letter-spacing: 0.01em;
}
@media (max-width: 1024px) {
  .l-header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.l-header .o-inner--wide {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.l-header h1 img {
  min-width: 192px;
  max-width: 192px;
}
@media (max-width: 599px) {
  .l-header h1 img {
    min-width: 150px;
    max-width: 150px;
  }
}
.l-header__nav {
  width: 50%;
}
@media (max-width: 1024px) {
  .l-header__nav {
    display: none;
  }
}
.l-header__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.l-header__item {
  font-size: 1.3rem;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
}
.l-header__item::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 6px;
  height: 7px;
  background-image: url(../img/common/nav/navIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.l-header__item:hover {
  cursor: pointer;
}
.l-header__item:hover::before {
  background-image: url(../img/common/nav/navIcon_on.png);
}
.l-header__item.button::before {
  content: none;
}
.l-header__item.arrow::after {
  content: none;
}
.l-header__item.arrow.is_active::before {
  background-image: url(../img/common/nav/navIcon_on.png);
}
.l-header__childNav {
  display: none;
  position: absolute;
  z-index: -999;
  top: 85px;
  left: 0;
  width: 100%;
  background: linear-gradient(90deg, #fce2d4 25%, #f9c7ce);
  padding: 40px 0 10px;
}
@media (max-width: 1024px) {
  .l-header__childNav {
    display: none !important;
  }
}
.l-header__childNav.is_active {
  z-index: 15;
}
.l-header__childNav.is_active .o-inner {
  position: relative;
}
.l-header__childNav.is_active .o-inner::after {
  content: "";
  display: inline-block;
  position: absolute;
  z-index: 20;
  top: -42px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 9px 0 9px;
  border-color: #ffffff transparent transparent transparent;
}
.l-header__childNav.is_active.js-childNavProduct .o-inner::after {
  left: auto;
  right: 9%;
}
.l-header__childNav .block {
  display: flex;
}
.l-header__childNav .block + .block {
  margin-top: 45px;
}
.l-header__childNav .block .label {
  margin-right: 35px;
  font-weight: 700;
  line-height: 100%;
  font-size: 1.2rem;
  letter-spacing: 0.01em;
  width: 200px;
  min-width: 200px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: linear-gradient(90deg, #e1769d 25%, #d72072);
}
.l-header__childNav .block ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 10px;
  width: 100%;
}
.l-header__childNav .block ul::after {
  content: "";
  display: block;
  width: 32%;
}
.l-header__childNav .block ul li {
  display: flex;
  align-items: center;
  width: 32%;
  margin-bottom: 15px;
  line-height: 100%;
  font-size: 1.3rem;
  font-weight: 700;
}
.l-header__childNav .block ul li::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #d72072;
}
.l-header__childNav .block ul li.displayNone {
  display: none;
}
.l-header__childNav .block--green .label {
  background: linear-gradient(90deg, #89d06a 25%, #38b72a);
}
.l-header__childNav .block--green ul li::before {
  background-color: #38b72a;
}
.l-header__childNav .block--blue .label {
  background: linear-gradient(90deg, #83a9ff 25%, #4e81f4);
}
.l-header__childNav .block--blue ul li::before {
  background-color: #4e81f4;
}
.l-header__childNav .block--orange .label {
  background: linear-gradient(90deg, #ffb565 25%, #ff8400);
}
.l-header__childNav .block--orange ul li::before {
  background-color: #ff8400;
}
.l-header__childNav .block--product ul {
  justify-content: flex-start;
  width: 100%;
}
.l-header__childNav .flex {
  margin-top: 45px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.l-header__childNav .flex .block {
  width: 48%;
  margin-bottom: 30px;
}
.l-header__childNav .flex .block + .block {
  margin-top: 0;
}
.l-header__childNav .flex .block ul {
  flex-direction: column;
}
.l-header__childNav .flex .block ul li {
  width: 100%;
}

.l-footer {
  padding-top: 125px;
  margin-top: auto;
  letter-spacing: 0.01em;
}
@media (max-width: 599px) {
  .l-footer {
    padding-top: 80px;
  }
}
.l-footer__top {
  background: linear-gradient(90deg, #fce2d4 25%, #f9c7ce);
  padding: 40px;
  text-align: center;
}
.l-footer__top img {
  max-width: 192px;
}
@media (max-width: 599px) {
  .l-footer__top img {
    max-width: 150px;
  }
}
.l-footer__middle {
  position: relative;
  padding: 30px 0;
}
@media (max-width: 1024px) {
  .l-footer__middle {
    display: none;
  }
}
.l-footer__list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.l-footer__item {
  font-size: 1.3rem;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
}
.l-footer__item::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 6px;
  height: 7px;
  background-image: url(../img/common/nav/navIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.l-footer__item:hover {
  cursor: pointer;
}
.l-footer__item:hover::before {
  background-image: url(../img/common/nav/navIcon_on.png);
}
.l-footer__item.button::before {
  content: none;
}
.l-footer__item.arrow::after {
  content: none;
}
.l-footer__item.arrow.is_active::before {
  background-image: url(../img/common/nav/navIcon_on.png);
}
.l-footer__childNav {
  display: none;
  position: absolute;
  z-index: -999;
  bottom: 80px;
  left: 0;
  width: 100%;
  background: linear-gradient(90deg, #fce2d4 25%, #f9c7ce);
  padding: 40px 0 10px;
}
.l-footer__childNav.is_active {
  z-index: 15;
}
.l-footer__childNav.is_active .o-inner {
  position: relative;
}
.l-footer__childNav.is_active .o-inner::after {
  content: "";
  display: inline-block;
  position: absolute;
  z-index: 20;
  bottom: -40px;
  left: 9%;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 10px 9px;
  border-color: #ffffff transparent transparent transparent;
}
.l-footer__childNav.is_active.js-childNavProductFooter .o-inner::after {
  left: auto;
  right: 9%;
}
.l-footer__childNav .block {
  display: flex;
}
.l-footer__childNav .block + .block {
  margin-top: 45px;
}
.l-footer__childNav .block .label {
  margin-right: 35px;
  font-weight: 700;
  line-height: 100%;
  font-size: 1.2rem;
  letter-spacing: 0.01em;
  width: 200px;
  min-width: 200px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: linear-gradient(90deg, #e1769d 25%, #d72072);
}
.l-footer__childNav .block ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 10px;
  width: 100%;
}
.l-footer__childNav .block ul::after {
  content: "";
  display: block;
  width: 32%;
}
.l-footer__childNav .block ul li {
  display: flex;
  align-items: center;
  width: 32%;
  margin-bottom: 15px;
  line-height: 100%;
  font-size: 1.3rem;
  font-weight: 700;
}
.l-footer__childNav .block ul li::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #d72072;
}
.l-footer__childNav .block ul li.displayNone {
  display: none;
}
.l-footer__childNav .block--green .label {
  background: linear-gradient(90deg, #89d06a 25%, #38b72a);
}
.l-footer__childNav .block--green ul li::before {
  background-color: #38b72a;
}
.l-footer__childNav .block--blue .label {
  background: linear-gradient(90deg, #83a9ff 25%, #4e81f4);
}
.l-footer__childNav .block--blue ul li::before {
  background-color: #4e81f4;
}
.l-footer__childNav .block--orange .label {
  background: linear-gradient(90deg, #ffb565 25%, #ff8400);
}
.l-footer__childNav .block--orange ul li::before {
  background-color: #ff8400;
}
.l-footer__childNav .block--product ul {
  justify-content: flex-start;
  width: 100%;
}
.l-footer__childNav .flex {
  margin-top: 45px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.l-footer__childNav .flex .block {
  width: 48%;
  margin-bottom: 30px;
}
.l-footer__childNav .flex .block + .block {
  margin-top: 0;
}
.l-footer__childNav .flex .block ul {
  flex-direction: column;
}
.l-footer__childNav .flex .block ul li {
  width: 100%;
}
.l-footer__bottom {
  background: linear-gradient(90deg, #fce2d4 25%, #f9c7ce);
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 700;
}
.l-footer__bottom .o-inner--wide {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.l-footer__bottom .left {
  font-size: 1.1rem;
}
.l-footer__bottom .right {
  font-size: 1.1rem;
}
.l-footer__bottom .right a {
  display: inline-block;
}
.l-footer__bottom .right a + a {
  margin-left: 20px;
}
@media (max-width: 599px) {
  .l-footer__bottom .right a + a {
    margin-left: 10px;
  }
}

.l-main--underLayer {
  margin-top: 80px;
}
@media (max-width: 599px) {
  .l-main--underLayer {
    margin-top: 50px;
  }
}

.l-contents {
  background-color: rgba(249, 249, 249, 0.8);
  padding: 60px 40px;
}
@media (max-width: 599px) {
  .l-contents {
    padding: 20px;
  }
}
.l-contents + .l-contents {
  margin-top: 85px;
}
@media (max-width: 599px) {
  .l-contents + .l-contents {
    margin-top: 40px;
  }
}

.o-inner {
  max-width: 1040px;
  padding: 0 20px;
  margin: 0 auto;
}
.o-inner--wide {
  max-width: 1440px;
}
.o-inner--narrow {
  max-width: 840px;
}

.o-title {
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
  line-height: 100%;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
}
.o-title span {
  color: #d72072;
}
.o-title::after {
  content: "";
  display: block;
  width: 100%;
  max-width: 100px;
  height: 2px;
  margin: 10px auto 0;
  background: linear-gradient(90deg, #fce2d4 25%, #f9c7ce);
}

.o-contentsTitle {
  font-weight: 700;
  text-align: center;
  font-size: 1.7rem;
  line-height: 1.0588235294;
  color: #d72072;
  line-height: 100%;
  margin-bottom: 50px;
}
@media (max-width: 599px) {
  .o-contentsTitle {
    line-height: 1.5;
    margin-bottom: 30px;
  }
}

.o-table {
  width: 100%;
}
.o-table th,
.o-table td {
  padding: 10px 15px;
  vertical-align: top;
}
@media (max-width: 599px) {
  .o-table th,
.o-table td {
    padding: 5px 10px;
  }
}
.o-table th {
  background-color: #ffeded;
  border: 1px solid #e8a0ba;
  text-align: center;
  font-weight: 400;
}
.o-table td {
  background-color: #ffffff;
  border: 1px solid #e8a0ba;
}
.o-table td span {
  font-weight: 700;
}

.o-button {
  background: linear-gradient(90deg, #ffac7f 25%, #ff8092);
  background-position: 1% 50%;
  background-size: 100% auto;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  line-height: 100%;
  font-size: 1.3rem;
  border-radius: 25px;
  width: 240px;
  height: 50px;
}
.o-button:hover {
  background-position: 99% 50%;
  background-size: 250% auto;
}
.o-button--min {
  width: 150px;
  height: 40px;
}

.o-hamburger {
  display: none;
}
@media (max-width: 1024px) {
  .o-hamburger {
    display: block;
    position: fixed;
    z-index: 999;
    top: 15px;
    right: 15px;
    width: 45px;
    height: 45px;
    background: linear-gradient(90deg, #fce2d4 25%, #f9c7ce);
    border: 2px solid #d72072;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.3s all ease-in-out;
  }
  .o-hamburger:hover {
    cursor: pointer;
  }
  .o-hamburger span {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 2px;
    background-color: #ffffff;
    transition: 0.3s all ease-in-out;
  }
  .o-hamburger span + span {
    margin-top: 5px;
    transition: 0.3s all ease-in-out;
  }
  .o-hamburger.is_active span:nth-child(1) {
    transform: rotate(45deg);
  }
  .o-hamburger.is_active span:nth-child(2) {
    display: none;
  }
  .o-hamburger.is_active span:nth-child(3) {
    transform: rotate(-45deg);
  }
  .o-hamburger.is_active span + span {
    margin-top: 0;
    top: -2px;
  }
}

.o-navSp {
  display: none;
}
@media (max-width: 1024px) {
  .o-navSp {
    display: block;
    visibility: hidden;
    opacity: 0;
    padding: 10% 5% 20%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    opacity: 0;
    z-index: 99;
    height: 100%;
    width: 0;
    background: linear-gradient(90deg, #fce2d4 25%, #f9c7ce);
    transition: all 0.3s ease-in-out;
  }
  .o-navSp.is_active {
    visibility: visible;
    opacity: 1;
    width: 100%;
  }
}
.o-navSp__item {
  font-weight: 700;
}
.o-navSp__item + .o-navSp__item {
  margin-top: 25px;
}
.o-navSp__item .block {
  margin-top: 25px;
}
.o-navSp__item .block .label {
  margin-right: 35px;
  font-weight: 700;
  line-height: 100%;
  font-size: 1.2rem;
  letter-spacing: 0.01em;
  width: 150px;
  min-width: 150px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: linear-gradient(90deg, #e1769d 25%, #d72072);
}
.o-navSp__item .block ul {
  margin-top: 20px;
  padding-left: 20px;
}
.o-navSp__item .block ul li {
  display: flex;
  align-items: center;
  line-height: 100%;
  font-size: 1.2rem;
  font-weight: 700;
}
.o-navSp__item .block ul li + li {
  margin-top: 15px;
}
.o-navSp__item .block ul li::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #d72072;
}
.o-navSp__item .block ul li.displayNone {
  display: block;
}
.o-navSp__item .block--green .label {
  background: linear-gradient(90deg, #89d06a 25%, #38b72a);
}
.o-navSp__item .block--green ul li::before {
  background-color: #38b72a;
}
.o-navSp__item .block--blue .label {
  background: linear-gradient(90deg, #83a9ff 25%, #4e81f4);
}
.o-navSp__item .block--blue ul li::before {
  background-color: #4e81f4;
}
.o-navSp__item .block--orange .label {
  background: linear-gradient(90deg, #ffb565 25%, #ff8400);
}
.o-navSp__item .block--orange ul li::before {
  background-color: #ff8400;
}
.o-navSp__item .flex {
  margin-top: 25px;
}

.o-breadcrumbs {
  overflow: hidden;
  background: linear-gradient(-90deg, #ffffff, #fce2d4 50%, #f9c7ce);
  display: inline-block;
  padding: 8px 15% 8px 20px;
}
@media (min-width: 1441px) {
  .o-breadcrumbs {
    display: block;
    padding: 8px 20px;
  }
}
.o-breadcrumbs .o-inner {
  padding: 0;
}
@media (min-width: 1441px) {
  .o-breadcrumbs .o-inner {
    padding: 0 20px;
  }
}
.o-breadcrumbs ul {
  display: inline-flex;
  align-items: center;
}
.o-breadcrumbs li {
  display: flex;
  align-items: center;
}
.o-breadcrumbs li + li {
  margin-left: 10px;
}
.o-breadcrumbs li + li::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 2px 0 2px 6px;
  border-color: transparent transparent transparent #d3166b;
}
.o-breadcrumbs li a {
  color: #ffffff;
  font-size: 1.1rem;
  line-height: 100%;
  font-weight: 700;
}
.o-breadcrumbs li:last-child a {
  color: #000000;
}

.c-top__main {
  padding: 350px 20px 370px;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../img/top/bgCircle.png);
  background-repeat: repeat-y;
  background-size: contain;
}
@media (max-width: 1024px) {
  .c-top__main {
    padding-top: 270px;
  }
}
.c-top__main .mainArea {
  background-color: #ff9699;
  border-radius: 50%;
  width: 360px;
  height: 360px;
  text-align: center;
  padding: 50px;
  position: relative;
  z-index: 10;
}
@media (max-width: 1024px) {
  .c-top__main .mainArea {
    width: 260px;
    height: 260px;
    padding: 30px;
  }
}
.c-top__main .mainArea .brandBlock .image img {
  max-width: 200px;
}
@media (max-width: 1024px) {
  .c-top__main .mainArea .brandBlock .image img {
    max-width: 120px;
  }
}
.c-top__main .mainArea .brandBlock p {
  margin-top: 15px;
  font-size: 1.3rem;
  line-height: 1.7857142857;
  font-weight: 700;
  color: #ffffff;
}
@media (max-width: 1024px) {
  .c-top__main .mainArea .brandBlock p {
    font-size: 1.2rem;
  }
}
.c-top__main .mainArea .brandBlock a {
  background-color: #d72072;
  margin: 15px auto 0;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #ffffff;
  padding: 8px 20px;
}
@media (max-width: 1024px) {
  .c-top__main .mainArea .brandBlock a {
    font-size: 1.2rem;
  }
}
.c-top__main .bubbleArea .bubblePicture {
  position: absolute;
  z-index: 5;
  background-color: #ffefef;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 650px) {
  .c-top__main .bubbleArea .bubblePicture {
    display: none;
  }
}
@media (max-width: 1024px) {
  .c-top__main .bubbleArea .bubblePicture {
    width: 90px;
    height: 90px;
  }
}
.c-top__main .bubbleArea .bubblePicture img {
  position: relative;
  z-index: 10;
}
@media (max-width: 1024px) {
  .c-top__main .bubbleArea .bubblePicture img {
    max-width: 80px;
  }
}
.c-top__main .bubbleArea .bubblePicture:nth-child(8) {
  top: 73%;
  left: 1%;
  transform-origin: center bottom;
  animation: yurayura01 5.2s ease-in-out infinite;
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubblePicture:nth-child(8) {
    top: 0;
  }
}
@keyframes yurayura01 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 5px);
  }
  75% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translateY(0, 0);
  }
}
.c-top__main .bubbleArea .bubblePicture:nth-child(9) {
  top: 30%;
  right: 0%;
  transform-origin: center bottom;
  animation: yurayura01 5.5s ease-in-out infinite;
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubblePicture:nth-child(9) {
    top: 0;
  }
}
@keyframes yurayura01 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 5px);
  }
  75% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translateY(0, 0);
  }
}
.c-top__main .bubbleArea .bubblePicture:nth-child(10) {
  top: 5%;
  left: 1%;
  transform-origin: center bottom;
  animation: yurayura01 5.7s ease-in-out infinite;
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubblePicture:nth-child(10) {
    top: 0;
  }
}
@keyframes yurayura01 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 5px);
  }
  75% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translateY(0, 0);
  }
}
.c-top__main .bubbleArea .bubbleBox {
  position: absolute;
  z-index: 5;
  background-color: #ffefef;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .c-top__main .bubbleArea .bubbleBox {
    width: 120px;
    height: 120px;
  }
}
.c-top__main .bubbleArea .bubbleBox .border {
  transition: all 1s ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: relative;
  width: 200px;
  height: 1px;
  background-color: #db357f;
}
@media (max-width: 1024px) {
  .c-top__main .bubbleArea .bubbleBox .border {
    width: 225px;
  }
}
.c-top__main .bubbleArea .bubbleBox img {
  position: relative;
  z-index: 10;
}
@media (max-width: 1024px) {
  .c-top__main .bubbleArea .bubbleBox img {
    max-width: 80px;
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(1) {
  top: 10%;
  left: 35%;
  transform-origin: center bottom;
  animation: yurayura01 5s ease-in-out infinite;
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(1) {
    top: 0;
  }
}
@keyframes yurayura01 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 5px);
  }
  75% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translateY(0, 0);
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(1) .border {
  top: 140px;
  left: 30px;
  transform: rotate(65deg);
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(1) .border {
    transform: rotate(95deg);
    top: 150px;
    left: 0;
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(2) {
  top: 15%;
  right: 15%;
  transform-origin: center bottom;
  animation: yurayura02 6s ease-in-out infinite;
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(2) {
    right: 5%;
  }
}
@keyframes yurayura02 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-5px, 5px);
  }
  75% {
    transform: translate(5px, -5px);
  }
  100% {
    transform: translateY(0, 0);
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(2) .border {
  top: 130px;
  left: -110px;
  transform: rotate(-45deg);
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(2) .border {
    left: -70px;
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(3) {
  top: 45%;
  right: 10%;
  transform-origin: center bottom;
  animation: yurayura03 5s ease-in-out infinite;
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(3) {
    top: 60%;
    right: 5%;
  }
}
@keyframes yurayura03 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 5px);
  }
  75% {
    transform: translate(-5px, -5px);
  }
  100% {
    transform: translateY(0, 0);
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(3) .border {
  top: -10px;
  left: -120px;
  transform: rotate(15deg);
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(3) .border {
    top: -70px;
    left: -40px;
    transform: rotate(60deg);
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(4) {
  top: 70%;
  right: 20%;
  transform-origin: center bottom;
  animation: yurayura04 5.5s ease-in-out infinite;
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(4) {
    top: 73%;
    right: 20%;
  }
}
@keyframes yurayura04 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-5px, -5px);
  }
  75% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translateY(0, 0);
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(4) .border {
  top: -100px;
  left: -90px;
  transform: rotate(55deg);
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(4) .border {
    left: -20px;
    transform: rotate(85deg);
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(5) {
  top: 65%;
  left: 30%;
  transform-origin: center bottom;
  animation: yurayura05 6.5s ease-in-out infinite;
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(5) {
    top: 72%;
    left: 5%;
  }
}
@keyframes yurayura05 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, -5px);
  }
  75% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translateY(0, 0);
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(5) .border {
  top: -50px;
  left: 20px;
  transform: rotate(-65deg);
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(5) .border {
    top: -100px;
    left: 60px;
    transform: rotate(-65deg);
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(6) {
  top: 45%;
  left: 10%;
  transform-origin: center bottom;
  animation: yurayura06 6.5s ease-in-out infinite;
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(6) {
    top: 57%;
    left: 3%;
  }
}
@keyframes yurayura06 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 5px);
  }
  75% {
    transform: translate(-5px, -5px);
  }
  100% {
    transform: translateY(0, 0);
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(6) .border {
  top: 20px;
  left: 110px;
  transform: rotate(-5deg);
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(6) .border {
    top: -30px;
    left: 50px;
    transform: rotate(-45deg);
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(7) {
  top: 20%;
  left: 15%;
  transform-origin: center bottom;
  animation: yurayura07 5.5s ease-in-out infinite;
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(7) {
    top: 15%;
    left: 3%;
  }
}
@keyframes yurayura07 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-5px, -5px);
  }
  75% {
    transform: translate(-5px, -5px);
  }
  100% {
    transform: translateY(0, 0);
  }
}
.c-top__main .bubbleArea .bubbleBox:nth-child(7) .border {
  top: 110px;
  left: 100px;
  transform: rotate(45deg);
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:nth-child(7) .border {
    top: 70px;
    left: 30px;
  }
}
.c-top__main .bubbleArea .bubbleBox:hover {
  background-color: #db357f;
  cursor: pointer;
}
.c-top__main .bubbleArea .bubbleBox:hover .border {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 599px) {
  .c-top__main .bubbleArea .bubbleBox:hover {
    background-color: inherit;
  }
}
.c-top__main .bubbleArea .bubbleBox.is_active {
  background-color: #db357f;
}
.c-top__main .bubbleArea .bubbleBox.is_active .border {
  opacity: 1;
  visibility: visible;
}
.c-top__message {
  position: relative;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.c-top__message .swiper-container {
  position: absolute;
  top: 0;
  z-index: 5;
}
.c-top__message .swiper-container .swiper-wrapper {
  transition-timing-function: linear;
}
.c-top__message .swiper-container .swiper-slide {
  height: 615px !important;
  /* 高さを指定 */
  width: auto !important;
}
.c-top__message .swiper-container .swiper-slide img {
  width: auto;
  height: 100%;
}
.c-top__message .block {
  position: relative;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px 30px 30px;
  max-width: 660px;
  margin: 0 20px;
}
.c-top__message .block h3 {
  text-align: center;
  font-size: 3rem;
  letter-spacing: 0.1em;
  line-height: 1.5333333333;
  color: #d72072;
  text-shadow: 2px 2px 0 #ffffff, -2px -2px 0 #ffffff, -2px 2px 0 #ffffff, 2px -2px 0 #ffffff, 0px 2px 0 #ffffff, 0 -2px 0 #ffffff, -2px 0 0 #ffffff, 2px 0 0 #ffffff;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
}
@media (max-width: 599px) {
  .c-top__message .block h3 {
    font-size: 2rem;
  }
}
.c-top__message .block p {
  text-align: center;
  margin-top: 50px;
  font-size: 1.8rem;
  letter-spacing: 0.1em;
  line-height: 1.6666666667;
  text-shadow: 2px 2px 0 #ffffff, -2px -2px 0 #ffffff, -2px 2px 0 #ffffff, 2px -2px 0 #ffffff, 0px 2px 0 #ffffff, 0 -2px 0 #ffffff, -2px 0 0 #ffffff, 2px 0 0 #ffffff;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
}
@media (max-width: 599px) {
  .c-top__message .block p {
    margin-top: 25px;
    font-size: 1.6rem;
  }
}
.c-top__message .block p + p {
  margin-top: 45px;
}
@media (max-width: 599px) {
  .c-top__message .block p + p {
    margin-top: 15px;
  }
}
.c-top__message .block .logo {
  margin-top: 30px;
  text-align: center;
}
@media (max-width: 599px) {
  .c-top__message .block .logo {
    margin-top: 20px;
  }
}
.c-top__message .block .shake_animation {
  animation: 5s linear infinite rotation;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  1% {
    transform: rotate(23deg);
  }
  2% {
    transform: rotate(-21deg);
  }
  4% {
    transform: rotate(13deg);
  }
  6% {
    transform: rotate(-10deg);
  }
  7% {
    transform: rotate(2deg);
  }
  8% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.c-top__news {
  margin-top: 100px;
}
.c-top__news .button {
  margin-top: 60px;
}
@media (max-width: 599px) {
  .c-top__news .button {
    margin-top: 40px;
  }
}
.c-top__news .button .o-button {
  margin: 0 auto;
}

.c-about__block + .c-about__block {
  margin-top: 40px;
}
@media (max-width: 599px) {
  .c-about__block + .c-about__block {
    margin-top: 30px;
  }
}
.c-about__block h4 {
  color: #d72072;
  line-height: 100%;
  font-weight: 700;
  padding-bottom: 25px;
}
@media (max-width: 599px) {
  .c-about__block h4 {
    padding-bottom: 15px;
  }
}
.c-about__block .o-table {
  width: 100%;
}
.c-about__block .o-table table {
  width: 100%;
}
.c-about__block .o-table table th {
  vertical-align: middle;
}
.c-about__block p {
  line-height: 2.1428571429;
}
.c-about__block a {
  color: #d72072;
  text-decoration: underline;
}
.c-about__businessNameList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.c-about__businessNameList li {
  width: 18%;
  margin-bottom: 25px;
  text-align: center;
}
@media (max-width: 1024px) {
  .c-about__businessNameList li {
    width: 31%;
  }
}
@media (max-width: 599px) {
  .c-about__businessNameList li {
    width: 48%;
  }
}
.c-about__businessNameList li img {
  mix-blend-mode: multiply;
}
.c-about__businessNameList li p {
  margin-top: 15px;
  line-height: 1.2857142857;
}
.c-about__businessNameList li.dammy {
  display: block;
  width: 18%;
}
@media (max-width: 1024px) {
  .c-about__businessNameList li.dammy {
    width: 31%;
  }
}
@media (max-width: 599px) {
  .c-about__businessNameList li.dammy {
    display: none;
  }
}
.c-about__imageCenter {
  text-align: center;
  padding-bottom: 30px;
}
.c-about__imageCenter--pbNone {
  padding-bottom: 0;
}
.c-about__sdgsBlock {
  margin-top: 40px;
  margin-bottom: 60px;
}
.c-about__sdgsBox {
  margin-bottom: 30px;
}
.c-about__sdgsBox .dot {
  position: relative;
  background-color: #faa5c5;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 0 auto 20px;
  min-width: auto;
  min-height: auto;
}
.c-about__sdgsBox .dot::before, .c-about__sdgsBox .dot::after {
  position: absolute;
  content: "";
  display: inline-block;
  background-color: #faa5c5;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.c-about__sdgsBox .dot::before {
  left: -18px;
}
.c-about__sdgsBox .dot::after {
  right: -18px;
}
.c-about__sdgsBox h5 {
  font-weight: 700;
  text-align: center;
  padding-bottom: 15px;
}
.c-about__sdgsBox ul {
  display: flex;
  justify-content: center;
}
@media (max-width: 599px) {
  .c-about__sdgsBox ul {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .c-about__sdgsBox ul::after {
    content: "";
    display: block;
    width: 30%;
  }
}
.c-about__sdgsBox ul li {
  margin: 0 10px;
  max-width: 90px;
}
@media (max-width: 599px) {
  .c-about__sdgsBox ul li {
    text-align: center;
    max-width: 100%;
    width: 30%;
    margin: 0 0 10px;
  }
  .c-about__sdgsBox ul li img {
    width: 100%;
  }
}
.c-about__category {
  display: flex;
  justify-content: center;
}
@media (max-width: 599px) {
  .c-about__category {
    justify-content: space-between;
  }
}
.c-about__category h5 {
  width: 102px;
  font-weight: 700;
  text-align: center;
  line-height: 100%;
  margin: 0 8px;
}
@media (max-width: 599px) {
  .c-about__category h5 {
    font-size: 1.2rem;
    width: 30%;
  }
}
.c-about__category h5::after {
  content: "";
  display: block;
  margin-top: 8px;
  width: 100%;
  height: 2px;
}
.c-about__category h5:nth-child(1)::after {
  background: linear-gradient(90deg, #e1769d 25%, #d72072);
}
.c-about__category h5:nth-child(2)::after {
  background: linear-gradient(90deg, #ffac7f 25%, #ff8092);
}
.c-about__category h5:nth-child(3)::after {
  background: linear-gradient(90deg, #89d06a 25%, #38b72a);
}
.c-about__summary {
  margin-top: 50px;
  max-height: 500px;
  overflow-y: scroll;
}
.c-about__summary .block {
  display: flex;
  justify-content: space-between;
}
.c-about__summary .block + .block {
  margin-top: 25px;
}
.c-about__summary .block h5 {
  width: 10%;
  min-width: 90px;
  font-weight: 700;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.7142857143;
  display: flex;
  flex-direction: column;
}
.c-about__summary .block h5::after {
  content: "";
  display: block;
  margin-top: auto;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #e1769d 25%, #d72072);
}
.c-about__summary .block h5.green::after {
  background: linear-gradient(90deg, #89d06a 25%, #38b72a);
}
.c-about__summary .block h5.blue::after {
  background: linear-gradient(90deg, #83a9ff 25%, #4e81f4);
}
.c-about__summary .block h5.orange::after {
  background: linear-gradient(90deg, #ffac7f 25%, #ff8092);
}
.c-about__summary .block h5.gray::after {
  background: #e9e9e9;
}
.c-about__summary .block ul {
  width: 90%;
  padding-left: 15px;
}
.c-about__summary .block ul::after {
  content: "";
  display: block;
  margin-top: 25px;
  width: 100%;
  height: 2px;
  background-color: #e9e9e9;
}
.c-about__summary .block ul li {
  line-height: 1.7142857143;
  position: relative;
  padding-left: 20px;
}
.c-about__summary .block ul li + li {
  margin-top: 25px;
}
.c-about__summary .block ul li::before {
  content: "";
  display: inline-block;
  margin-right: 6px;
  width: 7px;
  height: 7px;
  background-color: #dc6190;
  border-radius: 50%;
  position: absolute;
  top: 9px;
  left: 0;
}
.c-about__summary .block ul li.green::before {
  background-color: #38b72a;
}
.c-about__summary .block ul li.blue::before {
  background-color: #4e81f4;
}
.c-about__summary .block ul li.orange::before {
  background-color: #ffac7f;
}
.c-about__button {
  padding: 40px 0;
}
.c-about__button .o-button {
  margin: 0 auto;
  color: #ffffff;
  text-decoration: none;
}

.c-lecture-inspection__block + .c-lecture-inspection__block {
  margin-top: 70px;
}
@media (max-width: 599px) {
  .c-lecture-inspection__block + .c-lecture-inspection__block {
    margin-top: 50px;
  }
}
.c-lecture-inspection__block h4 {
  color: #d72072;
  line-height: 100%;
  font-weight: 700;
  padding-bottom: 30px;
}
@media (max-width: 599px) {
  .c-lecture-inspection__block h4 {
    padding-bottom: 20px;
  }
}
.c-lecture-inspection__block h5 {
  font-weight: 700;
  padding-bottom: 25px;
}
@media (max-width: 599px) {
  .c-lecture-inspection__block h5 {
    padding-bottom: 15px;
  }
}
.c-lecture-inspection__block h5.underline::after {
  content: "";
  display: block;
  width: 172px;
  height: 1px;
  background: linear-gradient(90deg, #ffac7f 25%, #ff8092);
  margin-top: 6px;
}
.c-lecture-inspection__block p {
  line-height: 1.7142857143;
  padding-bottom: 35px;
}
@media (max-width: 599px) {
  .c-lecture-inspection__block p {
    padding-bottom: 25px;
  }
}
.c-lecture-inspection__block ul {
  padding-bottom: 30px;
  padding-left: 15px;
}
@media (max-width: 599px) {
  .c-lecture-inspection__block ul {
    padding-bottom: 20px;
  }
}
.c-lecture-inspection__block ul li {
  position: relative;
  padding-left: 20px;
}
.c-lecture-inspection__block ul li + li {
  margin-top: 10px;
}
.c-lecture-inspection__block ul li::before {
  content: "";
  display: inline-block;
  margin-right: 6px;
  width: 7px;
  height: 7px;
  background-color: #dc6190;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 0;
}
.c-lecture-inspection__block ul li a {
  color: #d72072;
  text-decoration: underline;
}
.c-lecture-inspection__request h5 {
  text-align: center;
}
.c-lecture-inspection__request p {
  text-align: center;
}
.c-lecture-inspection__request .o-button {
  margin: 0 auto;
}
.c-lecture-inspection__flex {
  margin-top: 85px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 599px) {
  .c-lecture-inspection__flex {
    flex-direction: column;
  }
}
.c-lecture-inspection__flex .image {
  width: 37%;
}
@media (max-width: 599px) {
  .c-lecture-inspection__flex .image {
    width: 100%;
    text-align: center;
  }
}
.c-lecture-inspection__flex .image p {
  text-align: center;
  margin-top: 25px;
  color: #d72072;
  line-height: 100%;
  font-weight: 700;
}
.c-lecture-inspection__flex .contents {
  width: 60%;
}
@media (max-width: 599px) {
  .c-lecture-inspection__flex .contents {
    width: 100%;
    margin-top: 30px;
  }
}
.c-lecture-inspection__flex .contents h5 {
  padding-bottom: 15px;
}
.c-lecture-inspection__flex .contents ul {
  padding-left: 0;
  padding-bottom: 0;
}
.c-lecture-inspection__button {
  margin-top: 40px;
}
.c-lecture-inspection__button .o-button {
  margin: 0 auto;
}

.c-news__item {
  position: relative;
  background-color: rgba(249, 249, 249, 0.8);
  padding: 60px 35px 10px;
}
@media (max-width: 599px) {
  .c-news__item {
    padding: 60px 20px 10px;
  }
}
.c-news__item + li {
  margin-top: 20px;
}
.c-news__item time {
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(90deg, #fce2d4 25%, #f9c7ce);
  line-height: 100%;
  padding: 13px 20px;
}
.c-news__item h3 {
  color: #d72072;
  font-size: 1.7rem;
  line-height: 1.0588235294;
  font-weight: 700;
}
.c-news__item p {
  margin-top: 25px;
  line-height: 1.5714285714;
}
.c-news__item .labels {
  margin-top: 25px;
}
.c-news__item .labels li {
  background-color: #e8a0ba;
  padding: 5px 20px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  line-height: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-weight: 700;
}
.c-news__btn {
  width: 50px;
  height: 50px;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(90deg, #ffac7f 25%, #ff8092);
  background-position: 1% 50%;
  background-size: 100% auto;
  font-weight: 700;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 0;
}
.c-news__btn:hover {
  background-position: 99% 50%;
  background-size: 250% auto;
}
.c-news__btn:hover {
  cursor: pointer;
}
.c-news__pagination {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-news__pagination .prev,
.c-news__pagination .next {
  border-radius: 0;
  background: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 13px 6px 0;
  border-color: transparent #d3166b transparent transparent;
  margin-bottom: 5px;
  font-weight: 700;
}
.c-news__pagination .prev {
  margin-right: 15px;
}
.c-news__pagination .next {
  transform: rotate(180deg);
  margin-left: 15px;
}
.c-news__pagination .number {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.c-news__pagination .number li {
  margin: 0 5px 5px;
}
.c-news__pagination .number li a {
  min-width: 28px;
  min-height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #d72072;
  background-color: #fce2d4;
  border-radius: 4px;
  font-weight: 700;
}
.c-news__pagination .number li.current a {
  color: #ffffff;
  background: linear-gradient(90deg, #ffac7f 25%, #ff8092);
  background-position: 1% 50%;
  background-size: 100% auto;
}
.c-news__pagination .number li.current a:hover {
  background-position: 99% 50%;
  background-size: 250% auto;
}

.c-newsDetail__article {
  position: relative;
  padding: 60px 35px 25px;
}
@media (max-width: 599px) {
  .c-newsDetail__article {
    padding: 60px 20px 10px;
  }
}
.c-newsDetail__article time {
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(90deg, #fce2d4 25%, #f9c7ce);
  line-height: 100%;
  padding: 13px 20px;
}
.c-newsDetail__article h3 {
  color: #d72072;
  font-size: 1.7rem;
  line-height: 1.0588235294;
  font-weight: 700;
}
.c-newsDetail__article .contents {
  margin-top: 50px;
  line-height: 1.5714285714;
}
.c-newsDetail__article .contents .image {
  text-align: center;
  padding-bottom: 40px;
}
.c-newsDetail__article .contents .image p {
  margin-top: 10px;
  padding-bottom: 0;
}
.c-newsDetail__article .contents h4 {
  padding-bottom: 30px;
  font-weight: 700;
}
.c-newsDetail__article .contents h4.primary {
  color: #d72072;
}
.c-newsDetail__article .contents p {
  padding-bottom: 35px;
}
.c-newsDetail__article .contents a {
  color: #d72072;
  text-decoration: underline;
  word-break: break-all;
}
.c-newsDetail__article .contents .list {
  padding-bottom: 30px;
}
.c-newsDetail__article .contents .list li {
  position: relative;
  padding-left: 20px;
}
.c-newsDetail__article .contents .list li + li {
  margin-top: 10px;
}
.c-newsDetail__article .contents .list li::before {
  content: "";
  display: inline-block;
  margin-right: 6px;
  width: 12px;
  height: 12px;
  background-color: #d72072;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 0;
}
.c-newsDetail__article .contents .o-table {
  padding-bottom: 40px;
}
.c-newsDetail__article .contents .labels li {
  background-color: #e8a0ba;
  padding: 5px 20px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  line-height: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-weight: 700;
}
.c-newsDetail__pagination {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
.c-newsDetail__pagination .prev a,
.c-newsDetail__pagination .next a {
  font-weight: 700;
  color: #d72072;
}
.c-newsDetail__pagination .prev::before {
  content: "";
  display: inline-block;
  border-radius: 0;
  background: none;
  width: 0;
  height: 0;
  border-style: solid;
  margin-right: 10px;
  border-width: 6px 13px 6px 0;
  border-color: transparent #d3166b transparent transparent;
}
@media (max-width: 599px) {
  .c-newsDetail__pagination .prev::before {
    margin-right: 5px;
  }
}
.c-newsDetail__pagination .prev.linkNone a {
  pointer-events: none;
  opacity: 0.6;
}
.c-newsDetail__pagination .prev.linkNone::before {
  opacity: 0.6;
}
.c-newsDetail__pagination .next::after {
  content: "";
  display: inline-block;
  border-radius: 0;
  background: none;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: 10px;
  border-width: 6px 0 6px 13px;
  border-color: transparent transparent transparent #d3166b;
}
@media (max-width: 599px) {
  .c-newsDetail__pagination .next::after {
    margin-left: 5px;
  }
}
.c-newsDetail__pagination .next.linkNone a {
  pointer-events: none;
  opacity: 0.6;
}
.c-newsDetail__pagination .next.linkNone::after {
  opacity: 0.6;
}
.c-newsDetail__pagination .back {
  display: inline-block;
  background: linear-gradient(90deg, #ffac7f 25%, #ff8092);
  background-position: 1% 50%;
  background-size: 100% auto;
  border-radius: 4px;
  padding: 8px 16px;
  line-height: 100%;
  font-weight: 700;
}
.c-newsDetail__pagination .back:hover {
  background-position: 99% 50%;
  background-size: 250% auto;
}
@media (max-width: 599px) {
  .c-newsDetail__pagination .back {
    padding: 8px;
  }
}
.c-newsDetail__pagination .back a {
  color: #ffffff;
}

.c-products__head {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 599px) {
  .c-products__head {
    flex-direction: column;
  }
}
.c-products__head .logo {
  width: 30%;
}
@media (max-width: 599px) {
  .c-products__head .logo {
    width: 100%;
  }
}
.c-products__head .logo .circle {
  background-color: #ffdfdf;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 240px;
  min-width: 240px;
  min-height: 240px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .c-products__head .logo .circle {
    width: 180px;
    height: 180px;
    min-width: 180px;
    min-height: 180px;
  }
  .c-products__head .logo .circle img {
    max-width: 150px;
  }
}
@media (max-width: 599px) {
  .c-products__head .logo .circle {
    width: 240px;
    height: 240px;
    min-width: 240px;
    min-height: 240px;
  }
  .c-products__head .logo .circle img {
    max-width: 100%;
  }
}
.c-products__head .contents {
  width: 65%;
}
@media (max-width: 599px) {
  .c-products__head .contents {
    width: 100%;
    margin-top: 30px;
  }
}
.c-products__head .contents h3 {
  color: #d72072;
  font-size: 2rem;
  line-height: 100%;
  font-weight: 700;
}
.c-products__head .contents p {
  margin-top: 10px;
  font-size: 1.5rem;
  line-height: 1.6;
}
.c-products__head .contents p span {
  font-weight: 700;
}
.c-products__head .contents .block {
  margin-top: 35px;
}
.c-products__head .contents .block h4 {
  font-size: 1.7rem;
  line-height: 1.0588235294;
  font-weight: 700;
}
.c-products__head .contents .block p {
  margin-top: 15px;
}
.c-products__body {
  margin-top: 60px;
  background-color: rgba(249, 249, 249, 0.8);
  padding: 60px;
}
@media (max-width: 1024px) {
  .c-products__body {
    padding: 40px;
  }
}
@media (max-width: 599px) {
  .c-products__body {
    padding: 20px;
  }
}
.c-products__body h3 {
  text-align: center;
  color: #d72072;
  font-size: 2rem;
  line-height: 100%;
  font-weight: 700;
}
.c-products__body .stepList {
  position: relative;
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 599px) {
  .c-products__body .stepList {
    flex-direction: column;
  }
}
.c-products__body .stepList li {
  position: relative;
  background-color: #dc6190;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 27px;
}
@media (max-width: 1024px) {
  .c-products__body .stepList li {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    margin: 0 15px;
  }
}
@media (max-width: 599px) {
  .c-products__body .stepList li {
    width: 150px;
    height: 150px;
    margin: 20px 0;
  }
}
.c-products__body .stepList li .circle {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 5px;
  color: #f44387;
  font-weight: 700;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  letter-spacing: 0;
}
@media (max-width: 1024px) {
  .c-products__body .stepList li .circle {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 599px) {
  .c-products__body .stepList li .circle {
    width: 45px;
    height: 45px;
  }
}
.c-products__body .stepList li p {
  text-align: center;
  font-weight: 700;
  line-height: 1.2857142857;
  color: #ffffff;
  padding-top: 10px;
}
@media (max-width: 1024px) {
  .c-products__body .stepList li p {
    font-size: 1.2rem;
  }
}
@media (max-width: 599px) {
  .c-products__body .stepList li p {
    font-size: 1.4rem;
  }
}
.c-products__body .stepList li p span {
  font-size: 1.2rem;
}
.c-products__body .stepList li p.lines {
  padding-top: 25px;
}
.c-products__body .stepList li.dot {
  background-color: #faa5c5;
  width: 7px;
  height: 7px;
  margin: 0;
  min-width: auto;
  min-height: auto;
}
.c-products__body .stepList li.dot::before, .c-products__body .stepList li.dot::after {
  position: absolute;
  content: "";
  display: inline-block;
  background-color: #faa5c5;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.c-products__body .stepList li.dot::before {
  left: -18px;
}
@media (max-width: 1024px) {
  .c-products__body .stepList li.dot::before {
    left: -9px;
  }
}
@media (max-width: 599px) {
  .c-products__body .stepList li.dot::before {
    left: auto;
    top: -12px;
  }
}
.c-products__body .stepList li.dot::after {
  right: -18px;
}
@media (max-width: 1024px) {
  .c-products__body .stepList li.dot::after {
    right: -9px;
  }
}
@media (max-width: 599px) {
  .c-products__body .stepList li.dot::after {
    right: auto;
    bottom: -12px;
  }
}
.c-products__body .stepBlock {
  margin-top: 65px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 599px) {
  .c-products__body .stepBlock {
    flex-direction: column;
  }
}
.c-products__body .stepBlock + .stepBlock {
  margin-top: 45px;
}
.c-products__body .stepBlock .image {
  width: 32%;
  text-align: center;
}
@media (max-width: 599px) {
  .c-products__body .stepBlock .image {
    width: 100%;
  }
}
.c-products__body .stepBlock .image p {
  margin-top: 15px;
  font-size: 1.2rem;
}
.c-products__body .stepBlock .contents {
  width: 65%;
}
@media (max-width: 599px) {
  .c-products__body .stepBlock .contents {
    width: 100%;
    margin-top: 20px;
  }
}
.c-products__body .stepBlock .contents .title {
  display: flex;
  align-items: center;
}
.c-products__body .stepBlock .contents .title span {
  background-color: #dc6190;
  color: #ffffff;
  font-weight: 700;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  letter-spacing: 0;
}
.c-products__body .stepBlock .contents .title h4 {
  color: #d72072;
  font-size: 1.6rem;
  line-height: 100%;
  font-weight: 700;
  margin-left: 15px;
}
.c-products__body .stepBlock .contents p {
  margin-top: 25px;
  line-height: 2.1428571429;
}
@media (max-width: 599px) {
  .c-products__body .stepBlock .contents p {
    margin-top: 10px;
  }
}
.c-products__body .stepBlock .contents .box + .box {
  margin-top: 45px;
}
.c-products__body .stepBlock .contents a {
  color: #d9387e;
  text-decoration: underline;
}
.c-products__foot {
  margin-top: 50px;
}
.c-products__foot h3 {
  text-align: center;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 100%;
  margin-bottom: 15px;
}
.c-products__foot p {
  text-align: center;
  font-weight: 700;
  line-height: 2.1428571429;
}
.c-products__foot p.nomal {
  font-weight: 400;
}
.c-products__foot .o-button {
  margin: 40px auto;
}
.c-products__linkText {
  margin-top: 10px;
}
.c-products__linkText a {
  color: #d72072;
}

.c-privacy__contents {
  padding-bottom: 65px;
}
@media (max-width: 599px) {
  .c-privacy__contents {
    padding-bottom: 20px;
  }
}
.c-privacy__contents h4 {
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.5714285714;
  color: #d72072;
  margin-top: 60px;
}
.c-privacy__contents p + p {
  margin-top: 30px;
}
.c-privacy__contact {
  text-align: center;
  margin-top: 50px;
}
.c-privacy__contact h3 {
  margin-bottom: 0;
  line-height: 100%;
}
.c-privacy__contact p {
  font-weight: 700;
  margin-top: 20px;
  line-height: 100%;
}
.c-privacy__contact .o-button {
  margin: 20px auto 0;
}

.c-report .l-contents {
  padding-bottom: 20px;
}
.c-report__wrapper {
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .c-report__wrapper {
    padding: 0;
  }
}
@media (max-width: 599px) {
  .c-report__wrapper {
    flex-direction: column;
  }
}
.c-report__wrapper::after {
  content: "";
  display: block;
  width: 30%;
}
.c-report__block {
  width: 30%;
  margin-bottom: 60px;
}
@media (max-width: 599px) {
  .c-report__block {
    width: 100%;
    margin-bottom: 0;
  }
  .c-report__block + .c-report__block {
    margin-top: 60px;
  }
}
.c-report__block .inner {
  max-width: 172px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .c-report__block .inner {
    max-width: 100%;
    margin: 0;
  }
}
.c-report__block h4 {
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 15px;
}
.c-report__block h4::after {
  content: "";
  display: block;
  width: 100%;
  max-width: 172px;
  height: 1px;
  background: linear-gradient(90deg, #ffac7f 25%, #ff8092);
  margin-top: 6px;
}
.c-report__block ul li {
  line-height: 100%;
  position: relative;
  padding-left: 25px;
}
.c-report__block ul li + li {
  margin-top: 15px;
}
.c-report__block ul li::before {
  content: "";
  display: inline-block;
  margin-right: 6px;
  width: 7px;
  height: 7px;
  background-color: #dc6190;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 9px;
}
.c-report__block ul li a {
  display: inline-flex;
  align-items: center;
}
.c-report__block ul li a::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 16px;
  background-image: url(../img/report/pdf-icon.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 10px;
}

.c-it-talent__container + .c-it-talent__container {
  margin-top: 100px;
}
@media (max-width: 599px) {
  .c-it-talent__container + .c-it-talent__container {
    margin-top: 70px;
  }
}
.c-it-talent__block + .c-it-talent__block {
  margin-top: 50px;
}
@media (max-width: 599px) {
  .c-it-talent__block + .c-it-talent__block {
    margin-top: 35px;
  }
}
.c-it-talent__list {
  padding-left: 10px;
  margin-top: 15px;
}
.c-it-talent__list li {
  line-height: 1.7142857143;
  position: relative;
  padding-left: 20px;
}
.c-it-talent__list li + li {
  margin-top: 10px;
}
.c-it-talent__list li::before {
  content: "";
  display: inline-block;
  margin-right: 6px;
  width: 7px;
  height: 7px;
  background-color: #dc6190;
  border-radius: 50%;
  position: absolute;
  top: 9px;
  left: 0;
}
.c-it-talent__list li a {
  color: #d72072;
  text-decoration: underline;
}
.c-it-talent__top {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 599px) {
  .c-it-talent__top {
    flex-direction: column;
  }
}
.c-it-talent__top .box {
  width: 30%;
}
@media (max-width: 599px) {
  .c-it-talent__top .box {
    width: 100%;
  }
  .c-it-talent__top .box + .box {
    margin-top: 30px;
  }
}
@media (max-width: 599px) {
  .c-it-talent__top .box img {
    width: 100%;
  }
}
.c-it-talent__top .box p {
  margin-top: 20px;
  line-height: 1.7142857143;
}
.c-it-talent__top .box .c-it-talent__list {
  padding-left: 0;
}
.c-it-talent__bottom {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 599px) {
  .c-it-talent__bottom {
    flex-direction: column;
    margin-top: 30px;
  }
}
.c-it-talent__bottom .left {
  width: 30.5%;
}
@media (max-width: 599px) {
  .c-it-talent__bottom .left {
    width: 100%;
  }
}
@media (max-width: 599px) {
  .c-it-talent__bottom .left img {
    width: 100%;
  }
}
.c-it-talent__bottom .left p {
  margin-top: 10px;
  text-align: center;
  font-size: 1.2rem;
}
.c-it-talent__bottom .right {
  width: 65%;
}
@media (max-width: 599px) {
  .c-it-talent__bottom .right {
    width: 100%;
  }
}
.c-it-talent__bottom .right p {
  line-height: 1.7142857143;
}
@media (max-width: 599px) {
  .c-it-talent__bottom .right p {
    margin-top: 20px;
  }
}
.c-it-talent__bottom .right p + p {
  margin-top: 25px;
}

.c-programming-school .o-contentsTitle {
  margin-bottom: 40px;
}
.c-programming-school__container + .c-programming-school__container {
  margin-top: 80px;
}
@media (max-width: 599px) {
  .c-programming-school__container + .c-programming-school__container {
    margin-top: 50px;
  }
}
.c-programming-school__container p.bold {
  font-weight: 700;
}
.c-programming-school__container ul + ul {
  margin-top: 20px;
}
@media (max-width: 599px) {
  .c-programming-school__container ul + ul {
    margin-top: 15px;
  }
}
.c-programming-school__container ul li {
  position: relative;
  padding-left: 15px;
}
.c-programming-school__container ul li + li {
  margin-top: 5px;
}
.c-programming-school__container ul li::before {
  content: "";
  display: inline-block;
  margin-right: 6px;
  width: 7px;
  height: 7px;
  background-color: #dc6190;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 0;
}
.c-programming-school__container ul li a {
  color: #d72072;
  text-decoration: underline;
}
.c-programming-school__container ul li.iconNone::before {
  content: none;
}
.c-programming-school__flex {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 599px) {
  .c-programming-school__flex {
    flex-direction: column;
  }
}
.c-programming-school__flex--center {
  margin-top: 30px;
  justify-content: center;
}
.c-programming-school__flex--center .box {
  margin: 0 20px;
}
@media (max-width: 599px) {
  .c-programming-school__flex--center .box {
    margin: 0;
  }
}
@media (max-width: 599px) and (max-width: 599px) {
  .c-programming-school__flex--center .box + .box {
    margin-top: 30px;
  }
}
.c-programming-school__flex--center .box .image {
  text-align: center;
  margin-bottom: 25px;
}
.c-programming-school__flex--center .box h4 {
  font-weight: 700;
  margin-bottom: 5px;
}
.c-programming-school__flex--center .box h4 span {
  font-weight: 400;
}
.c-programming-school__flex--between .left {
  text-align: center;
  min-width: 280px;
}
@media (max-width: 599px) {
  .c-programming-school__flex--between .left {
    min-width: auto;
  }
}
.c-programming-school__flex--between .right {
  padding-left: 35px;
}
@media (max-width: 599px) {
  .c-programming-school__flex--between .right {
    padding-left: 0;
    margin-top: 30px;
  }
}
.c-programming-school__flex--between .right ul {
  margin-top: 25px;
}
.c-programming-school__contact .block {
  text-align: center;
}
.c-programming-school__contact .block + .block {
  margin-top: 30px;
}
.c-programming-school__contact .block h4 {
  font-weight: 700;
}
.c-programming-school__contact .block a {
  color: #d72072;
  text-decoration: underline;
}

@media (max-width: 599px) {
  .c-sabaepc .pseudo {
    display: block;
    content: "";
    height: 5px;
  }
}
.c-sabaepc .pc-br {
  display: inline-block;
}
@media (max-width: 599px) {
  .c-sabaepc .pc-br {
    display: none;
  }
}
.c-sabaepc .top {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.c-sabaepc .top a {
  position: relative;
  margin-top: 40px;
}
@media (max-width: 599px) {
  .c-sabaepc .top a {
    margin-top: 20px;
  }
}
.c-sabaepc .top img {
  height: auto;
}
.c-sabaepc .btn {
  font-weight: 700;
  background-color: #e31851;
  color: #fff;
  font-size: 1.8rem;
  border-radius: 40px;
  margin: 0 auto;
  padding: 20px 80px 20px 80px;
}
@media (max-width: 1024px) {
  .c-sabaepc .btn {
    padding: 15px 75px 15px 75px;
  }
}
@media (max-width: 599px) {
  .c-sabaepc .btn {
    font-size: 1rem;
    border-radius: 30px;
    padding: 10px 30px 10px 30px;
  }
}
.c-sabaepc .btnsub {
  font-weight: 700;
  display: inline-block;
  font-size: 1.8rem;
  background-color: #ffe063;
  border-radius: 40px;
  margin: 20px auto;
  padding: 20px 80px 20px 80px;
}
@media (max-width: 599px) {
  .c-sabaepc .btnsub {
    font-size: 1.4rem;
    margin: 10px auto;
    padding: 10px 30px 10px 30px;
  }
}
.c-sabaepc .home {
  font-size: 1.8rem;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  line-height: 30px;
}
@media (max-width: 599px) {
  .c-sabaepc .home {
    font-size: 1.4rem;
    text-align: initial;
    padding-top: 20px;
    padding-bottom: 40px;
    line-height: 23px;
  }
}
.c-sabaepc .home .intro span {
  font-weight: 700;
  font-size: 1.9rem;
}
@media (max-width: 599px) {
  .c-sabaepc .home .intro span {
    font-size: 1.5rem;
  }
}
.c-sabaepc .home .hashtag {
  color: #eb3a6c;
}
.c-sabaepc .home .example {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
@media (max-width: 599px) {
  .c-sabaepc .home .example {
    gap: 0px;
  }
  .c-sabaepc .home .example .img {
    width: 100px;
  }
}
.c-sabaepc .main {
  font-size: 1.8rem;
  padding-top: 20px;
  padding-bottom: 50px;
  text-align: center;
}
@media (max-width: 599px) {
  .c-sabaepc .main {
    font-size: 1.4rem;
  }
}
.c-sabaepc .main .head {
  font-weight: 700;
  font-size: 3rem;
  margin-top: 60px;
  margin-bottom: 30px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .head {
    font-size: 2rem;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
.c-sabaepc .main .card {
  position: relative;
  flex-direction: column;
  max-width: 700px;
  margin: 50px auto;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0 gray;
  border-radius: 30px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .card {
    margin: 40px auto;
    padding: 10px;
    border-radius: 20px;
  }
}
.c-sabaepc .main .card .bubble {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 173px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .card .bubble {
    top: 10px;
    left: 10px;
    width: 90px;
  }
}
.c-sabaepc .main .card .area {
  margin-top: 120px;
  margin-bottom: 50px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .card .area {
    margin-top: 70px;
  }
}
.c-sabaepc .main .card .flow {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .card .flow {
    flex-direction: column-reverse;
    gap: 15px;
    margin-bottom: 70px;
  }
}
.c-sabaepc .main .card .flow div {
  position: relative;
  width: 320px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .card .flow div {
    width: initial;
    max-width: 330px;
  }
}
.c-sabaepc .main .card .flow div .title {
  font-weight: 700;
}
.c-sabaepc .main .card .flow div .triangle {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: calc(100% + 40px);
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 25px solid #ffcc00;
}
@media (max-width: 599px) {
  .c-sabaepc .main .card .flow div .triangle {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #ffcc00;
    top: calc(100% + 25px);
  }
}
.c-sabaepc .main .card .flow img {
  width: 222px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .card .flow img {
    width: 200px;
  }
}
.c-sabaepc .main .voice {
  margin-top: 130px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .voice {
    margin-top: 70px;
  }
}
.c-sabaepc .main .voice .voice-list {
  margin: 0px 100px;
  text-align: center;
}
@media (max-width: 1024px) {
  .c-sabaepc .main .voice .voice-list {
    margin: 0px 30px;
  }
}
@media (max-width: 599px) {
  .c-sabaepc .main .voice .voice-list {
    margin: 0px 10px;
  }
}
.c-sabaepc .main .voice .voice-item {
  display: flex;
  margin-bottom: 50px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .voice .voice-item {
    flex-direction: column;
  }
}
.c-sabaepc .main .voice .voice-item-img {
  order: 0;
  flex-shrink: 0;
}
.c-sabaepc .main .voice .voice-item-img img {
  width: 125px;
  margin-bottom: 5px;
}
.c-sabaepc .main .voice .voice-item-img p {
  font-weight: 700;
}
.c-sabaepc .main .voice .balloon-left {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  background: #ebebeb;
  margin-left: 50px;
  padding: 30px 20px;
  text-align: left;
  border-radius: 15px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .voice .balloon-left {
    order: 1;
    margin-top: 10px;
    margin-left: 0;
    padding: 15px;
  }
}
.c-sabaepc .main .voice .balloon-left::after {
  content: "";
  border: solid transparent;
  height: 0;
  width: 0;
  pointer-events: none;
  position: absolute;
  border-color: rgba(90, 230, 40, 0);
  border-top-width: 10px;
  border-bottom-width: 10px;
  border-left-width: 30px;
  border-right-width: 30px;
  margin-top: -10px;
  border-right-color: #ebebeb;
  right: 100%;
  top: 20%;
}
@media (max-width: 599px) {
  .c-sabaepc .main .voice .balloon-left::after {
    display: none;
  }
}
.c-sabaepc .main .voice .balloon-right {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  background: #ebebeb;
  margin-right: 50px;
  padding: 30px 20px;
  text-align: left;
  border-radius: 15px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .voice .balloon-right {
    order: 1;
    margin-top: 10px;
    margin-right: 0;
    padding: 15px;
  }
}
.c-sabaepc .main .voice .balloon-right::after {
  content: "";
  border: solid transparent;
  height: 0;
  width: 0;
  pointer-events: none;
  position: absolute;
  border-color: rgba(90, 230, 40, 0);
  border-top-width: 10px;
  border-bottom-width: 10px;
  border-left-width: 30px;
  border-right-width: 30px;
  margin-top: -10px;
  border-left-color: #ebebeb;
  left: 100%;
  top: 20%;
}
@media (max-width: 599px) {
  .c-sabaepc .main .voice .balloon-right::after {
    display: none;
  }
}
.c-sabaepc .main .detail {
  margin-top: 130px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .detail {
    margin-top: 70px;
  }
}
.c-sabaepc .main .detail .detail-item {
  border: 1px solid gray;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 40px;
  max-width: 550px;
  background-color: #fff;
  box-shadow: 2px 2px 4px 0 gray;
  border-radius: 15px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .detail .detail-item {
    margin-bottom: 30px;
  }
}
.c-sabaepc .main .detail .detail-item span {
  font-weight: 700;
  display: block;
  background: #ff7da2;
  color: #fff;
  text-align: center;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  font-size: 2rem;
  padding: 5px 0;
}
@media (max-width: 599px) {
  .c-sabaepc .main .detail .detail-item span {
    font-size: 1.6rem;
  }
}
.c-sabaepc .main .detail .inner {
  text-align: center;
  padding: 15px 10px;
}
.c-sabaepc .main .detail .inner .sup {
  font-size: 1.6rem;
}
@media (max-width: 599px) {
  .c-sabaepc .main .detail .inner .sup {
    font-size: 1.2rem;
  }
}
.c-sabaepc .main .entry {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.c-sabaepc .main .entry .catch {
  font-weight: 700;
  font-size: 2.2rem;
  margin-top: 90px;
  margin-bottom: 40px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .entry .catch {
    font-size: 1.7rem;
    margin-top: 70px;
    margin-bottom: 30px;
  }
}
.c-sabaepc .main .entry .foot {
  max-width: 550px;
  font-size: 1.4rem;
  margin-top: 50px;
}
@media (max-width: 599px) {
  .c-sabaepc .main .entry .foot {
    font-size: 1.2rem;
    margin-top: 40px;
  }
}

.c-sitemap__item {
  font-weight: 700;
}
.c-sitemap__item::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 6px;
  height: 7px;
  background-image: url(../img/common/nav/navIcon_on.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.c-sitemap__item + .c-sitemap__item {
  margin-top: 25px;
}
.c-sitemap__item .block {
  margin-top: 25px;
}
.c-sitemap__item .block .label {
  margin-right: 35px;
  font-weight: 700;
  line-height: 100%;
  font-size: 1.2rem;
  letter-spacing: 0.01em;
  width: 150px;
  min-width: 150px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: linear-gradient(90deg, #e1769d 25%, #d72072);
}
.c-sitemap__item .block ul {
  margin-top: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.c-sitemap__item .block ul::after {
  content: "";
  display: block;
  width: 32%;
}
.c-sitemap__item .block ul li {
  width: 32%;
  display: flex;
  align-items: center;
  line-height: 100%;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 15px;
}
@media (max-width: 599px) {
  .c-sitemap__item .block ul li {
    width: 100%;
  }
}
.c-sitemap__item .block ul li::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #d72072;
}
.c-sitemap__item .block ul li.displayNone {
  display: block;
}
.c-sitemap__item .block--green .label {
  background: linear-gradient(90deg, #89d06a 25%, #38b72a);
}
.c-sitemap__item .block--green ul li::before {
  background-color: #38b72a;
}
.c-sitemap__item .block--blue .label {
  background: linear-gradient(90deg, #83a9ff 25%, #4e81f4);
}
.c-sitemap__item .block--blue ul li::before {
  background-color: #4e81f4;
}
.c-sitemap__item .block--orange .label {
  background: linear-gradient(90deg, #ffb565 25%, #ff8400);
}
.c-sitemap__item .block--orange ul li::before {
  background-color: #ff8400;
}
.c-sitemap__item .flex {
  margin-top: 25px;
}
.c-sitemap__item--project::before {
  background-image: url(../img/common/nav/navIcon.png);
}
.c-sitemap__item--product::before {
  background-image: url(../img/common/nav/navIcon.png);
}
.c-sitemap__button {
  margin-top: 50px;
}
.c-sitemap__button .o-button {
  margin: 0 auto;
}
.c-sitemap__contact {
  text-align: center;
  margin-top: 50px;
}
.c-sitemap__contact h3 {
  font-weight: 700;
  text-align: center;
  font-size: 1.7rem;
  line-height: 1.0588235294;
  color: #d72072;
  line-height: 100%;
}
.c-sitemap__contact p {
  font-weight: 700;
  margin-top: 20px;
  line-height: 100%;
}
.c-sitemap__contact .o-button {
  margin: 20px auto 0;
}
.c-sitemap__contact .link {
  font-weight: 700;
  font-size: 1.1rem;
  display: block;
  margin-top: 20px;
}

/*--------------------------------------*
 * foundation
 *--------------------------------------*/
/*--------------------------------------*
 * layout
 *--------------------------------------*/
/*--------------------------------------*
 * object
 *--------------------------------------*/
/*-------------------
 * main
 *-------------------*/